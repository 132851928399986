/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-north-1",
    "aws_appsync_graphqlEndpoint": "https://fuueuzmkafb7fbx4psbmakyc34.appsync-api.eu-north-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-north-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-avjr7rzcxvf2nivfokdltez7vi",
    "aws_cognito_identity_pool_id": "eu-north-1:f80e4b1d-42d2-48c2-98dd-c5dc6858b10f",
    "aws_cognito_region": "eu-north-1",
    "aws_user_pools_id": "eu-north-1_a9Y8bfLVz",
    "aws_user_pools_web_client_id": "10an9k1iqsu7jh2id48nqag3am",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL",
        "PHONE_NUMBER"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
