import { View, Text, Animated, TouchableOpacity, ImageBackground, Dimensions, ScrollView } from 'react-native'
import React from 'react'
import Header from '../Components/Header';
import { useNavigation, useRoute } from '@react-navigation/native';
import { Feather, FontAwesome, Ionicons, AntDesign, MaterialIcons, MaterialCommunityIcons, Entypo } from '@expo/vector-icons'; 

const {width, height} = Dimensions.get('screen')

const Help = () => {

    const navigation = useNavigation();

    const ourOrange = '#FF4F00';
    const headImg = 'https://images.unsplash.com/photo-1674574124792-3be232f1957f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80'

  return (
    <View style={{flex: 1, backgroundColor: '#fcfcfc'}}>
        <Animated.View style={{flexDirection: 'row', height: 100, width: width}}>
        <ImageBackground source={{uri: headImg}} style={{height: '100%', width: width}}>
        <Animated.View style={{position: 'absolute', top: 0, left: 0, zIndex: 9000}}>
            <TouchableOpacity onPress={()=> navigation.goBack()} style={{height: 60, width: 60, backgroundColor: ourOrange, alignItems: 'center', justifyContent: 'center'}}>
                <Feather name='arrow-left' size={30} color={'#fcfcfc'}  />
            </TouchableOpacity>
        </Animated.View>
        <View style={{height: '100%', width: '100%', backgroundColor: 'black', opacity: 0.4}}>

        </View>

        </ImageBackground>

    

        </Animated.View>

        <ScrollView>
            
        <Animated.View style={{padding: 30}}>
            <Text style={{alignSelf: 'center', fontWeight: 'bold', fontSize: 30}}>Help</Text>

            <View style={{width: width /2, alignSelf: 'center', marginTop: 30}}>

                <View>
                    
                
            <Text style={{fontSize: 16, fontWeight: 'bold'}}>Buying tickets</Text>
            <Text style={{textAlign: 'justify', marginTop: 10, marginLeft: 0}}>
            Support@Ticketday.co.za</Text>
            
                </View>


                <View style={{marginTop: 30}}>
                    
                
                    <Text style={{fontSize: 16, fontWeight: 'bold'}}>Help with event</Text>
                    <Text style={{textAlign: 'justify', marginTop: 10, marginLeft: 0}}>
                    organiser@Ticketday.co.za</Text>
                    
                </View>
          
                <View style={{marginTop: 30}}>
                
                    <Text style={{fontSize: 16, fontWeight: 'bold'}}>Customer Queries</Text>
                    <Text style={{textAlign: 'justify', marginTop: 10, marginLeft: 0}}>
                    info@Ticketday.co.za</Text>
                    
                </View>

        

                <View style={{marginTop: 30}}>
                
                    <Text style={{fontSize: 16, fontWeight: 'bold'}}>Running an event</Text>
                    <Text style={{textAlign: 'justify', marginTop: 10, marginLeft: 0}}>
                    sales@Ticketday.co.za</Text>
                    
                </View>

                <View style={{marginTop: 30}}>
                
                    <Text style={{fontSize: 16, fontWeight: 'bold'}}>Manager</Text>
                    <Text style={{textAlign: 'justify', marginTop: 10, marginLeft: 0}}>
                    werner@Ticketday.co.za</Text>
                    
                </View>


            </View>

        
         
        

        </Animated.View>

        </ScrollView>

    </View>
  )
}

export default Help