import { View, Text, TouchableOpacity, ImageBackground, Dimensions, ScrollView, TextInput, Pressable } from 'react-native'
import React, {useState, useEffect, useRef} from 'react'
import Header from '../Components/Header';
import { useNavigation, useRoute } from '@react-navigation/native';
import { Feather, FontAwesome, Ionicons, AntDesign, MaterialIcons, MaterialCommunityIcons, Entypo } from '@expo/vector-icons'; 
import Animated, { FadeIn, FadeInLeft, FadeInRight, FadeOutLeft, FadeOut, SlideInDown, SlideOutDown, withTiming, useSharedValue, FadeOutRight, SlideInLeft, SlideInRight, useAnimatedStyle, SlideOutLeft, withSpring, runOnJS, FadeInUp, Easing,  } from 'react-native-reanimated';
import { Gesture, GestureDetector } from 'react-native-gesture-handler';


const {width, height} = Dimensions.get('screen')

const FormEvent = () => {

    const navigation = useNavigation();

    const ourOrange = '#FF4F00';
    const headImg = 'https://images.unsplash.com/photo-1674574124792-3be232f1957f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80'

    const [freeEvent, setFreeEvent] = useState(false)
    const [opening, setOpening] = useState('')
    const [country, setCountry] = useState('')


    const [openModal, setOpenModal] = useState(false)

    const AnimatedPressable = Animated.createAnimatedComponent(Pressable)

    const offset = useSharedValue(0)

    const translateY = useAnimatedStyle(()=> ({
        transform: [{translateY: offset.value}]
    }))

    const toggleNot = () => {
        setOpenModal(!openModal)
        offset.value = 0;
      }
    
      const OVERDRAG = 100


      const pan = Gesture.Pan().onChange((event) => {

    
        const offsetDelta = event.changeY + offset.value;
        const clamp = Math.max(-OVERDRAG, offsetDelta);
        offset.value = offsetDelta > 0 ? offsetDelta : withSpring(clamp);
       }).onFinalize(()=> {
    
        if(offset.value < height /3){
          offset.value = withSpring(0)
        } else {
          offset.value = withTiming(height, {}, ()=> {
           runOnJS(toggleNot)();
          })
        }
        
       })




  return (
    <View style={{flex: 1, backgroundColor: '#fcfcfc'}}>
        <Animated.View style={{flexDirection: 'row', height: 100, width: width}}>
        <ImageBackground source={{uri: headImg}} style={{height: '100%', width: width}}>
        <Animated.View style={{position: 'absolute', top: 0, left: 0, zIndex: 9000}}>
            <TouchableOpacity onPress={()=> navigation.goBack()} style={{height: 60, width: 60, backgroundColor: ourOrange, alignItems: 'center', justifyContent: 'center'}}>
                <Feather name='arrow-left' size={30} color={'#fcfcfc'}  />
            </TouchableOpacity>
        </Animated.View>
        <View style={{height: '100%', width: '100%', backgroundColor: 'black', opacity: 0.4}}>

        </View>

        </ImageBackground>

    

        </Animated.View>

        
            
        <Animated.View style={{padding: 30}}>
            <Text style={{alignSelf: 'center', fontWeight: 'bold', fontSize: 20}}>List your event</Text>
            <Text style={{alignSelf: 'center', fontSize: 12, color: 'gray'}}>Let us help you list your event.</Text>

            <ScrollView>
                
            <View style={{width: '100%', alignSelf: 'center', marginTop: 30}}>
                
            <View style={{marginTop: 10}}>
                <View style={{}}>
                    <Text style={{fontSize: 12, color: 'gray', marginBottom: 5}}>Full name</Text>
                    <View style={{height: 45, width: '100%', borderWidth: 0.5, borderColor: 'gray', borderRadius: 5, paddingLeft: 0, alignItems: 'center', flexDirection: 'row'}}>
                       <TextInput placeholder='Full name' style={{fontSize: 12, height: '100%', textAlign: 'auto', width: '100%', paddingLeft: 10 }} /> 
                    </View>                   
                </View>
                <View style={{marginTop: 20}}>
                    <Text style={{fontSize: 12, color: 'gray', marginBottom: 5}}>E-Mail Address</Text>
                    <View style={{height: 45, width: '100%', borderWidth: 0.5, borderColor: 'gray', borderRadius: 5, paddingLeft: 0, alignItems: 'center', flexDirection: 'row'}}>
                       <TextInput placeholder='E-Mail Address' style={{fontSize: 12, height: '100%', textAlign: 'auto', width: '100%', paddingLeft: 10}} keyboardType='email-address'  /> 
                    </View>                   
                </View>

                <View style={{marginTop: 20}}>
                    <Text style={{fontSize: 12, color: 'gray', marginBottom: 5}}>Event Title</Text>
                    <View style={{height: 45, width: '100%', borderWidth: 0.5, borderColor: 'gray', borderRadius: 5, paddingLeft: 0, alignItems: 'center', flexDirection: 'row'}}>
                       <TextInput placeholder='Event title' style={{fontSize: 12, height: '100%', textAlign: 'auto', width: '100%', paddingLeft: 10}} keyboardType='email-address'  /> 
                    </View>                   
                </View>

                <TouchableOpacity  
                onPress={()=> {setOpenModal(true), setOpening('Category')}}
                style={{marginTop: 20}}>
                    <Text style={{fontSize: 12, color: 'gray', marginBottom: 5}}>Category</Text>
                    <View style={{height: 45, width: '100%', borderWidth: 0.5, borderColor: 'gray', borderRadius: 5, paddingLeft: 0, alignItems: 'center', flexDirection: 'row'}}>
                       <TextInput placeholder='Category' editable={false} style={{fontSize: 12, height: '100%', textAlign: 'auto', width: '100%', paddingLeft: 10}} keyboardType='email-address'  /> 
                        <View style={{position: 'absolute', right: 5, height: '100%', width: 45, alignItems:'center', justifyContent: 'center' }}>
                        <AntDesign name="caretdown" size={10} color="black" />
                        </View>
                    
                    </View>                   
                </TouchableOpacity>

                
                <TouchableOpacity  
                onPress={()=> {setOpenModal(true), setOpening('Country') }}
                style={{marginTop: 20}}>
                    <Text style={{fontSize: 12, color: 'gray', marginBottom: 5}}>{country ? country : `Country`}</Text>
                    <View style={{height: 45, width: '100%', borderWidth: 0.5, borderColor: 'gray', borderRadius: 5, paddingLeft: 0, alignItems: 'center', flexDirection: 'row'}}>
                       <TextInput placeholder='Country' editable={false} style={{fontSize: 12, height: '100%', textAlign: 'auto', width: '100%', paddingLeft: 10}} keyboardType='email-address'  /> 
                        <View style={{position: 'absolute', right: 5, height: '100%', width: 45, alignItems:'center', justifyContent: 'center' }}>
                        <AntDesign name="caretdown" size={10} color="black" />
                        </View>
                    
                    </View>                   
                </TouchableOpacity>

                <View style={{marginTop: 20}}>
                    <Text style={{fontSize: 12, color: 'gray', marginBottom: 5}}>Event Description</Text>
                    <View style={{height: 200, width: '100%', borderWidth: 0.5, borderColor: 'gray', borderRadius: 5, paddingLeft: 0, alignItems: 'center', flexDirection: 'row'}}>
                       <TextInput placeholder='Best describe your event' multiline={true} style={{fontSize: 12, height: '100%', textAlign: 'auto', width: '100%', padding: 10}} keyboardType= 'default'  /> 
                    </View>                   
                </View>

                <View style={{marginTop: 20}}>
                    <Text style={{fontSize: 12, color: 'gray', marginBottom: 5}}>Is this a free event ?</Text>
                    <TouchableOpacity onPress={()=> setFreeEvent(!freeEvent)} style={{height: 45, width: 45, borderWidth: 0.5, borderColor: 'gray', borderRadius: 5, paddingLeft: 0, alignItems: 'center', justifyContent: 'center', flexDirection: 'row'}}>
                        {freeEvent && 
                        <Text style={{fontWeight: 'bold', fontSize: 12}}>X</Text>
                        } 
                    </TouchableOpacity>                   
                </View>


                {!freeEvent && 
                
                <View style={{marginTop: 20}}>
                    <Text style={{fontSize: 12, color: 'gray', marginBottom: 5}}>How much is your cheapest tickets (in Rands / Emalangeni)</Text>
                    <View style={{height: 45, width: '100%', borderWidth: 0.5, borderColor: 'gray', borderRadius: 5, paddingLeft: 0, alignItems: 'center', flexDirection: 'row'}}>
                       <TextInput placeholder='Price' style={{fontSize: 12, height: '100%', textAlign: 'auto', width: '100%', paddingLeft: 10}} keyboardType= 'number-pad'  /> 
                    </View>                   
                </View>
                }


                <TouchableOpacity 
                onPress={()=> {}} 
                style={{flexDirection: 'row', backgroundColor: ourOrange, borderRadius: 5, marginTop: 20, justifyContent: 'center', alignItems: 'center', height: 45, width: '100%',}}>
                    <Text style={{fontSize: 12, fontWeight: 'bold', color: '#fcfcfc', marginRight: 5}}>Submit Request</Text>
                    <Feather name='arrow-right' size={15} color={'#fcfcfc'} />
                </TouchableOpacity>
                
            </View>



            



            <Text style={{textAlign: 'justify', marginTop: 10}}>Customer contact: 069 99 07817</Text>

          






            </View>

            </ScrollView>






        </Animated.View>
        
        { openModal &&

<>

<AnimatedPressable
onPress={()=> {setOpenModal(!openModal), setOpening('')}}
entering={FadeIn}
exiting={FadeOut}
style={{backgroundColor: 'rgba(0,0,0,0.8)', width: width, height: '100%', position: 'absolute', zIndex: 9000,}} /> 

{/* <GestureDetector gesture={pan}> */}

    <Animated.View 
    entering={SlideInDown.springify().damping(15)}
    exiting={SlideOutDown}
    style={[translateY, {position: 'absolute', zIndex: 10000, width: width, height: height/2, borderTopEndRadius: 10, borderTopStartRadius:10, backgroundColor: '#fcfcfc', bottom: 0}]}>
            
            <View style={{ top: 0, width: '100%', padding: 15, backgroundColor: 'white', borderBottomWidth: 0.5, borderColor: 'rgba(0,0,0,0.3)', borderTopEndRadius: 10, borderTopLeftRadius: 10}}>
            
            <Animated.View style={{flexDirection: 'row', alignItems: 'center'}}>
                
            <Pressable onPress={()=> {setOpenModal(!openModal), setOpening('')} }>
                <Entypo name="squared-cross" size={24} color="black" />
            </Pressable>

            <Text style={{fontWeight: 'bold', fontSize: 12, marginLeft: 15}}>{opening}</Text>
                
            </Animated.View>

            
            </View>

                
            <View>
                {opening === 'Country' && 
                    <View style={{width: '100%', height: '100%', padding: 30}}>
                        <TouchableOpacity 
                        onPress={()=> {setCountry('South Africa'), setOpenModal(!openModal)}}
                        style={{height: 60, width: '100%', alignItems: 'center', justifyContent: 'center', borderRadius: 5, backgroundColor: country === 'South Africa' ? ourOrange : 'rgba(227, 95, 0, 0.3)'}}>
                            <Text style={{fontWeight: 'bold', color: country === 'South Africa' ? '#fcfcfc' : 'gray'}}>South Africa</Text>
                        </TouchableOpacity>

                        <TouchableOpacity 
                        onPress={()=>{ setCountry('Eswatini'), setOpenModal(!openModal)}}
                        style={{height: 60, width: '100%', marginTop: 15, alignItems: 'center', justifyContent: 'center', borderRadius: 5, backgroundColor:  country === 'Eswatini' ? ourOrange : 'rgba(227, 95, 0, 0.3)'}}>
                            <Text style={{fontWeight: 'bold', color: country === 'Eswatini' ? '#fcfcfc' : 'gray'}}>Eswatini</Text>
                        </TouchableOpacity>
                    </View>
                }
            </View>
            
    </Animated.View>  

{/* </GestureDetector> */}

</>

}


    </View>
  )
}

export default FormEvent