import { StatusBar } from 'expo-status-bar';
import { StyleSheet, Text, View, Dimensions, TextInput, TouchableOpacity, ActivityIndicator, ImageBackground, Animated, Image } from 'react-native';
import { useState, useEffect, useRef } from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import Plug from './Plug';
import Results from './Results';
import { useNavigation } from '@react-navigation/native';
import Details from './Details';
import { Auth } from 'aws-amplify';
import { API, graphqlOperation } from 'aws-amplify';
import {getUserData} from './src/graphql/queries';
import { AntDesign, FontAwesome } from '@expo/vector-icons';
import PaystackPop from '@paystack/inline-js'
import { LinearGradient  } from 'expo-linear-gradient';
import HomeMain from './HomeMain';
import EventDetails from './Screens/EventDetails';
import EventTickets from './Screens/EventTickets';
import MyTickets from './Screens/MyTickets';
import TicketDetails from './Screens/TicketDetails';
import Cart from './Screens/Cart';
import Payment from './Screens/Payment';
import CategorySearch from './Screens/CategorySearch';
import Downloads from './Screens/Downloads';
import ChatSupport from './Screens/ChatSupport';
import ChatScreen from './Screens/ChatScreen';
import LoginFlow from './Components/LoginFlow';
import * as Linking from 'expo-linking';
import * as WebBrowser from 'expo-web-browser';
import EventDetailsLink from './Screens/EventDetailsLink';
import EventTicketsLink from './Screens/EventTicketsLink';
import PrivacyPolicy from './Screens/PrivacyPolicy';
import AboutUs from './Screens/AboutUs';
import TsnCs from './Screens/TsnCs';
import FormEvent from './Screens/FormEvent';

const {width, height} = Dimensions.get('screen')
const Stack = createNativeStackNavigator();
import { ContextProvider } from './src/contexts/ContextProvider';


import { Amplify } from 'aws-amplify';
import awsExports from './src/aws-exports';
import Profile from './Screens/Profile';
import Search from './Screens/Search';
import FAQs from './Screens/FAQs';
import Help from './Screens/Help';
import ListEvent from './Screens/ListEvent';
Amplify.configure(awsExports);

function HomeScreen() {
    return (
      <View style={{ flex: 1, backgroundColor: '#f2f2f2', alignItems: 'center', justifyContent: 'center'}}>
       <Plug/>
      </View>
    );
  }


export default function App() {

  const [choose,setChoose] = useState('Main');
  const [emailAddress, setEmailAddress] = useState('');
  const [password ,setPassword] = useState('');
  const [idNumber ,setIdNumber] = useState('');
  const [userInfo, setUserInfo] = useState();
  const [spinning, setSpinning] = useState(false);
  const [errorMs ,setErrorMs] = useState('');
  const [secureEnt, setSecureEnt] = useState(true)


  const [data, setData] = useState(null);


  const handleDeepLink = (event) => {
    let data = Linking.parse(event.url)
    setData(data)

    console.log(event)
    return
  }

  

  useEffect(() => {
    
    if(width >= 751){
      Linking.openURL('https://www.ticketday.co.za')
      return
    }

    Animated.timing(opacityE, {
      toValue: 1,
      duration: 900,
      delay: 350,
      useNativeDriver: true,
    }).start();

    Animated.timing(opacityF, {
      toValue: 1,
      duration: 900,
      delay: 1500,
      useNativeDriver: true,
    }).start();


    setTimeout(() => {
      setChoose('done')
    }, 6000);

    

  }, [])

  // useEffect(() => {
  //   Linking.addEventListener("url", handleDeepLink)

  //   Linking.getInitialURL().then((ee)=> {
      

  //   console.log(JSON.stringify(ee))
  //  })

  
   
  //   console.log(data && JSON.stringify(data.path))

  // }, [data])
  
  
 

  const ExplTextY = useRef(new Animated.Value(height)).current; 
const ourOrange = '#FF4F00';
const opacityA = useRef(new Animated.Value(0)).current;
    const opacityB = useRef(new Animated.Value(0)).current;
    const opacityE = useRef(new Animated.Value(0)).current;
    const opacityF = useRef(new Animated.Value(0)).current;


  if(choose === 'Main'){


      return(
        <Animated.View style={{flex: 1, backgroundColor: '#fcfcfc', alignItems: 'center', justifyContent: 'center', opacity: opacityE}}>
               <Image source={require('./assets/dar.png')} style={{width: width / 2, height: 60, resizeMode: 'contain'}} /> 
               <Animated.View style={{opacity: opacityF}}>
                 <ActivityIndicator size={'small'} color={'purple'} style={{marginTop: 10}} />
               </Animated.View>
               
        </Animated.View>
      )
  }


  // const linking = {
  //   prefixes: [Linking.createURL("/"), "https://m.ticketday.co.za", "m.ticketday://"],
  // };


  const NotFoundScreen = () => {
    return (
      <View>
        <Text>404 - Page Not Found</Text>
        <Text>The requested page could not be found.</Text>
      </View>
    );
  };


  
  return (
    <ContextProvider>
      <View style={{flex: 1}}>

     
      
<NavigationContainer 
  // linking={linking}
>
<Stack.Navigator screenOptions={{
   headerShown: false,
}} initialRouteName="Home">
  {data?.path ? 
      <Stack.Screen name='Home'>
      {(props)=> <HomeMain {...props} linkdirect={data?.path}/>}
    </Stack.Screen>
  :
    <Stack.Screen name="Home" component={HomeMain}/> 
  }

  {/* <Stack.Screen name="Home" component={HomeMain}  datar={data.path} /> */}
  <Stack.Screen name="Search" component={Search} />
  <Stack.Screen name="Profile" component={Profile} />
  <Stack.Screen name="EventDetails" component={EventDetails} />
  <Stack.Screen name="EventTickets" component={EventTickets} />
  <Stack.Screen name="MyTickets" component={MyTickets} />
  <Stack.Screen name="TicketDetails" component={TicketDetails} />
  <Stack.Screen name="Cart" component={Cart} />
  <Stack.Screen name="Payment" component={Payment} />
  <Stack.Screen name="CategorySearch" component={CategorySearch} />
  <Stack.Screen name="Downloads" component={Downloads} />
  <Stack.Screen name="ChatSupport" component={ChatSupport} />
  <Stack.Screen name="ChatScreen" component={ChatScreen} />
  <Stack.Screen name="LoginFlow" component={LoginFlow} />
  <Stack.Screen name="EventDetailsLink" component={EventDetailsLink} />
  <Stack.Screen name="AboutUs" component={AboutUs} />
  <Stack.Screen name="TsnCs" component={TsnCs} />
  <Stack.Screen name="PrivacyPolicy" component={PrivacyPolicy} />
  <Stack.Screen name="faqs" component={FAQs} />
  <Stack.Screen name="help" component={Help} />
  <Stack.Screen name="FormEvent" component={FormEvent} />
  <Stack.Screen name="listevent" component={ListEvent} />
  <Stack.Screen name="NotFound" component={NotFoundScreen} options={{ headerShown: false }} />
</Stack.Navigator>
</NavigationContainer>
      </View>
    </ContextProvider>
    
  );
}
