/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getEvent = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      id
      name
      city
      country
      address
      description
      headlineAct
      eventDate
      eventTime
      campaignId
      ownerId
      startStatus
      image
      EventDays {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listEvents = /* GraphQL */ `
  query ListEvents(
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        city
        country
        address
        description
        headlineAct
        eventDate
        eventTime
        campaignId
        ownerId
        startStatus
        image
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEventDay = /* GraphQL */ `
  query GetEventDay($id: ID!) {
    getEventDay(id: $id) {
      id
      title
      event {
        id
        name
        city
        country
        address
        description
        headlineAct
        eventDate
        eventTime
        campaignId
        ownerId
        startStatus
        image
        createdAt
        updatedAt
      }
      tickets {
        nextToken
      }
      createdAt
      updatedAt
      eventEventDaysId
    }
  }
`;
export const listEventDays = /* GraphQL */ `
  query ListEventDays(
    $filter: ModelEventDayFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEventDays(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        createdAt
        updatedAt
        eventEventDaysId
      }
      nextToken
    }
  }
`;
export const getTicket = /* GraphQL */ `
  query GetTicket($id: ID!) {
    getTicket(id: $id) {
      id
      campaignId
      eventId
      name
      amount
      description
      qty
      eventDay {
        id
        title
        createdAt
        updatedAt
        eventEventDaysId
      }
      content
      active
      suspendedByUser
      createdAt
      updatedAt
      eventDayTicketsId
    }
  }
`;
export const listTickets = /* GraphQL */ `
  query ListTickets(
    $filter: ModelTicketFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTickets(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        campaignId
        eventId
        name
        amount
        description
        qty
        content
        active
        suspendedByUser
        createdAt
        updatedAt
        eventDayTicketsId
      }
      nextToken
    }
  }
`;
export const getUserData = /* GraphQL */ `
  query GetUserData($id: ID!) {
    getUserData(id: $id) {
      id
      firstName
      email
      phoneNumber
      profileComplete
      idNumber
      idcountryIssue
      businessName
      businessAddress
      idImage
      balance
      pendingBalance
      active
      bank
      branchNumber
      accountNumber
      bankCountry
      SwiftNumber
      withdrawnAmount
      createdAt
      updatedAt
    }
  }
`;
export const listUserData = /* GraphQL */ `
  query ListUserData(
    $filter: ModelUserDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserData(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstName
        email
        phoneNumber
        profileComplete
        idNumber
        idcountryIssue
        businessName
        businessAddress
        idImage
        balance
        pendingBalance
        active
        bank
        branchNumber
        accountNumber
        bankCountry
        SwiftNumber
        withdrawnAmount
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getVendorData = /* GraphQL */ `
  query GetVendorData($id: ID!) {
    getVendorData(id: $id) {
      id
      firstName
      email
      phoneNumber
      profileComplete
      idNumber
      idcountryIssue
      businessName
      businessAddress
      idImage
      balance
      pendingBalance
      createdAt
      updatedAt
    }
  }
`;
export const listVendorData = /* GraphQL */ `
  query ListVendorData(
    $filter: ModelVendorDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVendorData(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstName
        email
        phoneNumber
        profileComplete
        idNumber
        idcountryIssue
        businessName
        businessAddress
        idImage
        balance
        pendingBalance
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCart = /* GraphQL */ `
  query GetCart($id: ID!) {
    getCart(id: $id) {
      id
      eventId
      campaignId
      seatId
      email
      vendorId
      userId
      ownerId
      qty
      title
      image
      amount
      createdAt
      updatedAt
    }
  }
`;
export const listCarts = /* GraphQL */ `
  query ListCarts(
    $filter: ModelCartFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCarts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        eventId
        campaignId
        seatId
        email
        vendorId
        userId
        ownerId
        qty
        title
        image
        amount
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOrder = /* GraphQL */ `
  query GetOrder($id: ID!) {
    getOrder(id: $id) {
      id
      eventId
      campaignId
      ownerId
      seatId
      email
      vendorId
      userId
      qty
      title
      image
      orderAmount
      paymentStatus
      paymentReference
      fullName
      country
      active
      scanned
      eventDate
      eventTime
      eventLocation
      bookingUserName
      eventStatus
      createdAt
      updatedAt
    }
  }
`;
export const listOrders = /* GraphQL */ `
  query ListOrders(
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        eventId
        campaignId
        ownerId
        seatId
        email
        vendorId
        userId
        qty
        title
        image
        orderAmount
        paymentStatus
        paymentReference
        fullName
        country
        active
        scanned
        eventDate
        eventTime
        eventLocation
        bookingUserName
        eventStatus
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOrderTicket = /* GraphQL */ `
  query GetOrderTicket($id: ID!) {
    getOrderTicket(id: $id) {
      id
      eventId
      campaignId
      seatId
      email
      vendorId
      userId
      qty
      title
      image
      amount
      ownerId
      createdAt
      updatedAt
    }
  }
`;
export const listOrderTickets = /* GraphQL */ `
  query ListOrderTickets(
    $filter: ModelOrderTicketFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrderTickets(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        eventId
        campaignId
        seatId
        email
        vendorId
        userId
        qty
        title
        image
        amount
        ownerId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserInfo = /* GraphQL */ `
  query GetUserInfo($id: ID!) {
    getUserInfo(id: $id) {
      id
      firstName
      email
      phoneNumber
      createdAt
      updatedAt
    }
  }
`;
export const listUserInfos = /* GraphQL */ `
  query ListUserInfos(
    $filter: ModelUserInfoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserInfos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstName
        email
        phoneNumber
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCampaign = /* GraphQL */ `
  query GetCampaign($id: ID!) {
    getCampaign(id: $id) {
      id
      name
      description
      image
      active
      ownerId
      minAmount
      genre
      country
      approved
      declined
      banner
      secOne
      secTwo
      secThree
      secFour
      secFive
      startStatus
      createdAt
      updatedAt
    }
  }
`;
export const listCampaigns = /* GraphQL */ `
  query ListCampaigns(
    $filter: ModelCampaignFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCampaigns(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        image
        active
        ownerId
        minAmount
        genre
        country
        approved
        declined
        banner
        secOne
        secTwo
        secThree
        secFour
        secFive
        startStatus
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTransactions = /* GraphQL */ `
  query GetTransactions($id: ID!) {
    getTransactions(id: $id) {
      id
      userId
      amount
      inOrOut
      approved
      commment
      createdAt
      updatedAt
    }
  }
`;
export const listTransactions = /* GraphQL */ `
  query ListTransactions(
    $filter: ModelTransactionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTransactions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        amount
        inOrOut
        approved
        commment
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getChatRoom = /* GraphQL */ `
  query GetChatRoom($id: ID!) {
    getChatRoom(id: $id) {
      id
      userId
      open
      openCustomer
      userName
      adminName
      subject
      createdAt
      updatedAt
    }
  }
`;
export const listChatRooms = /* GraphQL */ `
  query ListChatRooms(
    $filter: ModelChatRoomFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChatRooms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        open
        openCustomer
        userName
        adminName
        subject
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getChats = /* GraphQL */ `
  query GetChats($id: ID!) {
    getChats(id: $id) {
      id
      userId
      read
      userName
      adminName
      subject
      message
      attachmentUrl
      ChatRoom
      createdAt
      updatedAt
    }
  }
`;
export const listChats = /* GraphQL */ `
  query ListChats(
    $filter: ModelChatsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChats(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        read
        userName
        adminName
        subject
        message
        attachmentUrl
        ChatRoom
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
