import { View, Text, TouchableOpacity, TextInput, Dimensions, FlatList, Image } from 'react-native'
import React, {useEffect, useState} from 'react'
import Header from '../Components/Header'
import { Feather, FontAwesome, Ionicons, AntDesign, MaterialIcons, Entypo } from '@expo/vector-icons'; 
import { useNavigation } from '@react-navigation/native';
import * as queries from '../src/graphql/queries';
import { FlashList } from '@shopify/flash-list';
import { API, Auth, graphqlOperation } from 'aws-amplify';
import {Picker} from '@react-native-picker/picker';



const {width, height} = Dimensions.get('screen')

const Search = () => {

  const navigation = useNavigation();
  const ourOrange = '#FF4F00';

  const [allEvents ,setAllEvents] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState('South Africa');

  const fetchEvents = async()=>{

 
  
    const allBenes = await API.graphql({ query: queries.listCampaigns, variables: 
       {
           filter :   
          //  { active: {eq: true
          //              }}
                       { and: [ 
                        {active: {eq: true}},
                        {country: {eq: selectedCountry}}
                 ]}
       } });  

     const benesList = allBenes.data.listCampaigns.items
     setAllEvents(benesList)
 }

 useEffect(() => {
  fetchEvents();
 }, [allEvents])


 const renderEvents = ({item}) => {

  return(
      <TouchableOpacity onPress={()=> navigation.navigate('EventDetails', {item: item})}>
        <View style={{height: 100, width: width - 30, backgroundColor: '#fcfcfc', paddingHorizontal: 10, borderRadius: 10, flexDirection: 'row', alignItems: 'center'}}>
          <Image source={{uri: item.image}} style={{height: 85, width: 85, borderRadius: 10, marginRight: 15}}/>
          <View style={{width: width - 160}}>
            <Text style={{width: width - 180, fontSize: 12, fontWeight: '700', marginBottom: 10}}>{item.name}</Text>
            <Text style={{fontSize: 10, color: 'gray', textAlign: 'justify', width: width - 180, height: 40}}>{String(item.description).slice(0,80)}...</Text>
          </View>
          
        </View>
      </TouchableOpacity>
  )
 }

 const itemSeperator = ()=> {
  return(
    <View style={{height: 10, width: width}} />
  )
 }

 const [filteredData, setFilteredData] = useState(allEvents);
    const [search, setSearch] = useState('');


 const searchFilter = (text) =>{
  if(text){
      const newData = allEvents.filter((item) => {
          const itemData = item.name ? item.name.toUpperCase() : ''.toUpperCase();
          const textData = text.toUpperCase();
          return itemData.indexOf(textData) > -1;
      });
      setFilteredData(newData);
      setSearch(text);   
  } else{
      setFilteredData(allEvents);
      setSearch(text)
  }
}








  return (
    <View style={{flex: 1, backgroundColor: '#edf1f2'}}>
      <View style={{zIndex: 120000}}>
        <Header/>
      </View>
        
        <View style={{flexDirection: 'row', alignSelf: 'center', marginTop: 20}}>
          <View style={{height: 50, width: width - 30, borderRadius: 10, borderWidth:0, borderColor: '#d1d1d1', alignItems: 'center', justifyContent: 'center', flexDirection: 'row'}}>
            <TouchableOpacity onPress={()=> navigation.goBack()} style={{borderRightWidth: 0.5, borderTopLeftRadius: 5, borderBottomLeftRadius: 5, backgroundColor: 'purple', borderColor: '#d1d1d1', height: '100%', width: 50, alignItems: 'center', justifyContent: 'center'}}>
              <Feather name='arrow-left' size={20} color={'#fcfcfc'} />
            </TouchableOpacity>
            <View style={{height: '100%', flexDirection: 'row', paddingLeft: 15, alignItems: 'center', backgroundColor: '#fcfcfc', borderTopRightRadius: 5, borderBottomRightRadius: 5}}>
              <Feather size={15} name='search' />
              <TextInput style={{fontSize: 12, marginLeft: 15, height: '100%', width: '85%', borderWidth: 0}} placeholder='Start typing to find an event' value={search} onChangeText={(text)=> searchFilter(text)} />
            </View>
          </View>
        </View>

        <View style={{height: height - 100, width: width, padding: 15}}>

          <View style={{flexDirection: 'row', marginBottom: 20, justifyContent: 'space-between'}}>
              <Text style={{fontWeight: 'bold', marginBottom: 0}}>Events</Text>

              <View style={{width: width / 3}}>
                <Text style={{fontSize: 12, fontWeight: 'bold'}}>Country</Text>
                <View>
                <Picker
                        selectedValue={selectedCountry}
                        onValueChange={(itemValue, itemIndex) =>
                            {setSelectedCountry(itemValue), fetchEvents()}
                        }>
                        <Picker.Item label="South Africa" value="South Africa" />
                        <Picker.Item label="Eswatini" value="Eswatini" />
                        </Picker>

                </View>
              </View>
          </View>

          


          <FlashList
            showsVerticalScrollIndicator={false}
            data={filteredData}
            renderItem={renderEvents}
            estimatedItemSize={100}
            ItemSeparatorComponent={itemSeperator}
          />
        </View>


      
    </View>
  )
}

export default Search