import { View, Text, Animated, TouchableOpacity, ImageBackground, Dimensions, ScrollView } from 'react-native'
import React from 'react'
import Header from '../Components/Header';
import { useNavigation, useRoute } from '@react-navigation/native';
import { Feather, FontAwesome, Ionicons, AntDesign, MaterialIcons, MaterialCommunityIcons, Entypo } from '@expo/vector-icons'; 

const {width, height} = Dimensions.get('screen')

const AboutUs = () => {

    const navigation = useNavigation();

    const ourOrange = '#FF4F00';
    const headImg = 'https://images.unsplash.com/photo-1674574124792-3be232f1957f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80'

  return (
    <View style={{flex: 1, backgroundColor: '#fcfcfc'}}>
        <Animated.View style={{flexDirection: 'row', height: 100, width: width}}>
        <ImageBackground source={{uri: headImg}} style={{height: '100%', width: width}}>
        <Animated.View style={{position: 'absolute', top: 0, left: 0, zIndex: 9000}}>
            <TouchableOpacity onPress={()=> navigation.goBack()} style={{height: 60, width: 60, backgroundColor: ourOrange, alignItems: 'center', justifyContent: 'center'}}>
                <Feather name='arrow-left' size={30} color={'#fcfcfc'}  />
            </TouchableOpacity>
        </Animated.View>
        <View style={{height: '100%', width: '100%', backgroundColor: 'black', opacity: 0.4}}>

        </View>

        </ImageBackground>

    

        </Animated.View>

        <ScrollView>
            
        <Animated.View style={{padding: 30}}>
            <Text style={{alignSelf: 'center', fontWeight: 'bold', fontSize: 30}}>About Us</Text>

            <View style={{width: width /2, alignSelf: 'center', marginTop: 30}}>
                
            <Text style={{textAlign: 'justify', marginTop: 10}}>
            Ticketday is a self-service ticketing platform that allows promoters to create an event and sell tickets
            online to meet a wider audience, meanwhile processing payment on their behalf. We have
            extremely simplified our website for anyone to buy tickets online without having to be a tech expert.
            With just a few simple clicks we promise to get your ticket to your hand as quickly as possible.
            Whether you are selling or buying a ticket, ticketday got your back!
            </Text>
            
          

            <Text style={{textAlign: 'justify', marginTop: 10}}>Customer contact: 069 99 07817

            </Text>

            <Text style={{textAlign: 'justify', marginTop: 10}}>When registering through the website as on the order form, we provide the option for the user to
            Help- support@ticketday.co.za
 Chat: 0633645696

            </Text>






            </View>




        </Animated.View>

        </ScrollView>

    </View>
  )
}

export default AboutUs