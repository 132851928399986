import { StyleSheet, Text, View } from 'react-native'
import React from 'react';
import { useStateContext } from '../src/contexts/ContextProvider';


const EmptyComp = () => {

    const {counting, setCounting, authed, setAuthed} = useStateContext();

  return (
    <View>
      <Text>tss : {counting}</Text>
    </View>
  )
}

export default EmptyComp

const styles = StyleSheet.create({})