import React, {createContext, useContext, useState} from "react";
const StateContext = createContext();

const initialState = {
    firstName: '',
    email: '',
    uid: ''
}

export const ContextProvider = ({children}) => {

    const [authed, setAuthed] = useState(null)
    const [counting, setCounting] = useState(1)
    const [authedUser, setAuthedUser] = useState(initialState)

    return(
        <StateContext.Provider
        value={{authed, setAuthed, counting, setCounting, authedUser, setAuthedUser}}
        >
            {children}
        </StateContext.Provider>

    )



}

export const useStateContext = () => useContext(StateContext)