import { StyleSheet, Text, View, TouchableOpacity, Dimensions, Image, ActivityIndicator, TextInput, Animated, FlatList } from 'react-native'
import React, {useState, useEffect} from 'react';
import { Feather } from '@expo/vector-icons'; 
import Header from '../Components/Header';
import { Auth, API } from 'aws-amplify';
import * as queries from '../src/graphql/queries';
const {width, height} = Dimensions.get('screen');
import { useStateContext } from '../src/contexts/ContextProvider';
import EmptyComp from '../Components/EmptyComp';
import LoginFlow from '../Components/LoginFlow';
import { useNavigation } from '@react-navigation/native';


const MyTickets = () => {
   const {counting, setCounting, authed, setAuthed} = useStateContext();

   const navigation = useNavigation()
  const [ticketsall, setTicketsAll] = useState([])

  const fetchingTickets = async()=> {

  
    if(authed){
      const userIdd = await Auth.currentAuthenticatedUser({bypassCache: true})

    

      const todoData = await API.graphql({query: queries.listOrders, variables:  
          {filter :   { userId: 
                  {eq: 
                      (userIdd?.attributes.sub)
                  },
                  }
           }
      })
          const todos = todoData.data.listOrders.items
          setTicketsAll(todos)
    }


    
}

useEffect(()=> {
    fetchingTickets();
}, [ticketsall, authed])


const ourOrange = '#FF4F00';


  if(authed === false){
    return (
      <View style={{flex: 1, backgroundColor: '#fcfcfc'}}>
        <View style={{padding: 20}}>
          <TouchableOpacity onPress={()=> navigation.goBack()}>
            <Feather size={30} name='arrow-left' />
          </TouchableOpacity>
        </View>
        <LoginFlow/>
      </View>
    )
    
     
  }


    const renderTickets = ({item, index})=> {
      return(
        <TouchableOpacity onPress={()=> navigation.navigate('TicketDetails', {item: item})} style={{height: 80, width: '100%', flexDirection: 'row', alignItems: 'center', backgroundColor: '#fcfcfc', borderRadius: 10, paddingLeft: 10}}>
          <Image 
            source={{uri: item.image}} 
            style={{height: 65, width: 75, resizeMode: 'stretch', borderRadius: 10}}
          />

          <View style={{marginLeft: 20}}>
            <Text style={{fontSize: 12, fontWeight: 'bold'}}>{item.title}</Text>
            <View style={{flexDirection: 'row', marginTop: 10}}>
              <Text style={{fontSize: 10}}>ZAR{Number(item.orderAmount).toFixed(2)}</Text>
              <Text style={{fontSize: 10, marginLeft: 20}}>{item.qty} Tickets</Text>
            </View>
          </View>

          
          {item.scanned && 
            <View style={{position: 'absolute', right: 15, bottom: 15, backgroundColor: 'red', padding: 2, borderRadius: 5}}>
                <Text style={{fontSize: 8, color: '#fcfcfc'}}>Scanned</Text>
            </View>

        }

        </TouchableOpacity>
      )
    }

    const itemSeperator = () => {
      return(
        <View style={{height: 10}} />
      )
    }

  return (
    <View style={{flex: 1, backgroundColor: '#edf1f2'}}>
        <View style={{zIndex: 19000}}>
         <Header/>
        </View>
        
          {authed === true ? 
          <View style={{padding: 25}}>

            <Text style={{fontSize: 16, fontWeight: 'bold', marginBottom: 15}}>My Tickets</Text>

            <FlatList
              showsVerticalScrollIndicator={false}
              data={ticketsall}
              renderItem={renderTickets}
              ItemSeparatorComponent={itemSeperator}
              style={{height: height / 1.5 + 20}}
            />
          </View>
          :



          <View style={{flex: 1, backgroundColor: '#fcfcfc', alignItems: 'center', justifyContent: 'center'}}>
          <Image source={require('../assets/iconsmsall.png')} style={{height: 30, width: 80, resizeMode: 'contain'}} />
          <Text style={{marginTop: 15}}>Loading your tickets...</Text>

          <ActivityIndicator size={'small'} color={'purple'} style={{marginTop: 10}} />
        
          </View>
          }
    </View>
  )
}

export default MyTickets

const styles = StyleSheet.create({})