import { View, Text, Animated, TouchableOpacity, ImageBackground, Dimensions, ScrollView } from 'react-native'
import React from 'react'
import Header from '../Components/Header';
import { useNavigation, useRoute } from '@react-navigation/native';
import { Feather, FontAwesome, Ionicons, AntDesign, MaterialIcons, MaterialCommunityIcons, Entypo } from '@expo/vector-icons'; 

const {width, height} = Dimensions.get('screen')

const PrivacyPolicy = () => {

    const navigation = useNavigation();

    const ourOrange = '#FF4F00';
    const headImg = 'https://images.unsplash.com/photo-1674574124792-3be232f1957f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80'

  return (
    <View style={{flex: 1, backgroundColor: '#fcfcfc'}}>
        <Animated.View style={{flexDirection: 'row', height: 100, width: width}}>
        <ImageBackground source={{uri: headImg}} style={{height: '100%', width: width}}>
        <Animated.View style={{position: 'absolute', top: 0, left: 0, zIndex: 9000}}>
            <TouchableOpacity onPress={()=> navigation.goBack()} style={{height: 60, width: 60, backgroundColor: ourOrange, alignItems: 'center', justifyContent: 'center'}}>
                <Feather name='arrow-left' size={30} color={'#fcfcfc'}  />
            </TouchableOpacity>
        </Animated.View>
        <View style={{height: '100%', width: '100%', backgroundColor: 'black', opacity: 0.4}}>

        </View>

        </ImageBackground>

    

        </Animated.View>

        <ScrollView>
            
        <Animated.View style={{padding: 30}}>
            <Text style={{alignSelf: 'center', fontWeight: 'bold', fontSize: 30}}>Privacy Policy</Text>

            <View style={{width: width /2, alignSelf: 'center', marginTop: 30}}>
                
            <Text style={{fontSize: 18, fontWeight: 'bold'}}>Personal Data</Text>
            <Text style={{textAlign: 'justify', marginTop: 10}}>
            This privacy policy applies to Ticketday. This privacy policy describes how Ticketday collects and uses the personal information you provide on our websites. It also describes the choices available to you regarding our use of your personal information and how you can access and update this information.</Text>
            
            <Text style={{textAlign: 'justify', marginTop: 10}}>Ticketday will never sell your name, email, or any other data collected (Personal Data) to third
                parties. Attendees Whenever you purchase a ticket, register, or donate to an event, the Organizer of
                that event will be able to view your Personal Data. Organizers may choose to publicly display your
                name on their event page for website visitors to see. This information is indexed by search engines.
                Whenever you purchase a ticket, register, or donate to an event on Ticketday you agree that we are
                not responsible for the actions of an Organizer with your Personal Data. You should review the User
                Content of the Organizer to determine if your Personal Data will be used in a way you agree with
                before taking any actions.</Text>

            <Text style={{textAlign: 'justify', marginTop: 10}}>However, we automatically collect and/or tracks various information, including but not limited to (1)
            the home server domain names, type of client computer, and type of web browser used to access
            the Site;
            </Text>

            <Text style={{textAlign: 'justify', marginTop: 10}}>When registering through the website as on the order form, we provide the option for the user to
                "opt out" of any non-essential communications. These communications include newsletters, new
                features updates as well as special offers. If you no longer wish to receive our newsletter and
                promotional communications, you may opt-out of receiving them by following the instructions
                included in each newsletter or communication or by emailing us at--
            </Text>






            </View>

            <View style={{width: width /2, alignSelf: 'center', marginTop: 30}}>
                
            <Text style={{fontSize: 18, fontWeight: 'bold'}}>Legal Requirements</Text>
            <Text style={{textAlign: 'justify', marginTop: 10}}>
            Ticketday may disclose your Personal Data if we are required to by law, or if in good faith we feel
that it may help avoid legal liabilities or protect the safety of Attendees            
</Text>



            </View>

            <View style={{width: width /2, alignSelf: 'center', marginTop: 30}}>
                
            <Text style={{fontSize: 18, fontWeight: 'bold'}}>Orders</Text>
            <Text style={{textAlign: 'justify', marginTop: 10}}>
            If you purchase a product or service from us, we request certain personally identifiable information
from you on our order form. You must provide contact information (such as name, email, and some
important information) and financial information (such as credit card number, expiration date). We
use this information for billing purposes and to fill your orders. If we have trouble processing an
order, we will use this information to contact you. We may also use this information to send you
newsletters or updates on your account. Clients have the right to opt-out of non-critical
communications such as newsletters or new feature updates. Client's may not opt out of critical
emails such as notifications of account status, or notification of account upgrades and/or account
cancellations  
</Text>



            </View>

            <View style={{width: width /2, alignSelf: 'center', marginTop: 30}}>
                
            <Text style={{fontSize: 18, fontWeight: 'bold'}}>PCI Compliance</Text>
            <Text style={{textAlign: 'justify', marginTop: 10}}>
            All third party payment processors used by Ticketday are fully PCI-DSS Level 1 compliant. This means
            that all credit card information is processed in a completely safe environment. To learn more about
            PCI Compliance and all it entails, you can find more information and documentation on PCI
            Compliances
 
            </Text>



            </View>

            <View style={{width: width /2, alignSelf: 'center', marginTop: 30}}>
                
            <Text style={{fontSize: 18, fontWeight: 'bold'}}>Authorized Third Party</Text>
            <Text style={{textAlign: 'justify', marginTop: 10}}>
            Information you provide to Ticketday may be shared with service providers and affiliates of
Ticketday for use solely on behalf of Ticketday and its affiliates in accordance with this Privacy Policy
 
            </Text>



            </View>

            <View style={{width: width /2, alignSelf: 'center', marginTop: 30}}>
                
            <Text style={{fontSize: 18, fontWeight: 'bold'}}>Other Sites</Text>
            <Text style={{textAlign: 'justify', marginTop: 10}}>
            Our website has links to the websites of our partners and other businesses. Please note that once
you leave the Ticketday website and link to another website, you are subject to the privacy policy of
the new site.

 
            </Text>



            </View>

            <View style={{width: width /2, alignSelf: 'center', marginTop: 30}}>
                
                <Text style={{fontSize: 18, fontWeight: 'bold'}}>Cookies</Text>
                <Text style={{textAlign: 'justify', marginTop: 10}}>
                A "cookie" is an element of data which is a piece of text, not a program and that a website can send
to the User's browser, which may then be stored on a User's computer system. The Site can access
the information from a cookie sent to a User's computer system. Ticketday does place cookies on
Users' computer systems, which may then be stored on a User's computer system. Ticketday cannot
access other cookies sent by other web sites or the information contained therein. We use cookies
to track usage of the Site and further customize User's experience when such is visiting the Site. By
tracking usage, Ticketday can best determine what features of the Site best serve each User. When a
User revisits the Site, we will recognize such User by the cookie and may tailor her/his visit
accordingly. Users may decline the cookie by using the appropriate feature of its web client
software, if available, however, some functionality of the Site may not function properly if the User
declines the cookie. We do not link the information we store in cookies to any personally identifiable
information you submit while on our site. We also use Google's keyword analytics to track
clickstream data. The use of cookies by our partners, affiliates, tracking utility company, service providers is not
covered by our privacy policy. We do not have access or control over these cookies. Our third party
partners employ clear gifs, images, and scripts that help them better manage content on our site.
We do not tie the information gathered to our Customers’ or Users’ personally identifiable
information   
     
                </Text>
    
    
    
            </View>     

            <View style={{width: width /2, alignSelf: 'center', marginTop: 30}}>
                
            <Text style={{fontSize: 18, fontWeight: 'bold'}}>Under the age of 13</Text>
            <Text style={{textAlign: 'justify', marginTop: 10}}>
            Ticketday does not knowingly collect or solicit personal information from anyone under the age of
            13, or knowingly allow such persons to register on its website. If you are under 13, please do not
            attempt to register or to send any information about yourself to us. No one under age 13 may
            provide or post any personal information on the Ticketday website. In the event that we learn that
            we have inadvertently collected personal information from a child under age 13 without verification
            of parental consent, we will promptly delete that information.

            </Text>



            </View>    

            <View style={{width: width /2, alignSelf: 'center', marginTop: 30}}>
                
            <Text style={{fontSize: 18, fontWeight: 'bold'}}>Changes to Privacy Policy</Text>
            <Text style={{textAlign: 'justify', marginTop: 10}}>
            If we decide to change our privacy policy, we will post those changes on our website so our users are
always aware of what information we collect, how we use it, and under what circumstances, if any,
we disclose it. If at any point we decide to use personally identifiable information in a manner
different from that stated at the time it was collected, we will notify users. Users will have a choice
as to whether or not we use their information in this different manner. Unless otherwise agreed by a
user, we will use information in accordance with the Privacy Policy under which the information was
collected.
            </Text>



            </View> 

            <View style={{width: width /2, alignSelf: 'center', marginTop: 30}}>
                
                <Text style={{fontSize: 18, fontWeight: 'bold'}}>Governing Law</Text>
                <Text style={{textAlign: 'justify', marginTop: 10}}>
                This Privacy Policy and its performance shall be interpreted and governed by the laws currently in
force in the Republic of South Africa. 
                </Text>
    
    
    
            </View> 

            <View style={{width: width /2, alignSelf: 'center', marginTop: 30}}>
                
                <Text style={{textAlign: 'justify', marginTop: 10}}>
                If you have questions or concerns regarding this statement, you should contact us first via one of the
methods listed below.
Whatsapp: 0633645696
Email : Info@ticketday.co.za 
                </Text>
    
    
    
            </View> 

        </Animated.View>

        </ScrollView>

    </View>
  )
}

export default PrivacyPolicy