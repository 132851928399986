import { StyleSheet, Text, View, Dimensions, TouchableOpacity, Animated, ImageBackground, FlatList, ScrollView, Image, Pressable } from 'react-native'
import React, {useState, useEffect} from 'react';
import Header from './Components/Header';
import { useNavigation } from '@react-navigation/native';
import { LinearGradient } from 'expo-linear-gradient';
import { Feather, FontAwesome, FontAwesome5 , Ionicons, AntDesign, MaterialIcons, Entypo } from '@expo/vector-icons'; 
import { FlashList } from "@shopify/flash-list";
import * as queries from './src/graphql/queries';

import { useStateContext } from './src/contexts/ContextProvider';

import { Auth, API, graphqlOperation } from 'aws-amplify';
import { getUserData } from './src/graphql/queries';
import * as Linking from 'expo-linking';


const {width, height} = Dimensions.get('screen')



const HomeMain = (datab) => {
  const {counting, setCounting, authed, setAuthed, authedUser, setAuthedUser} = useStateContext();
    const imageBack = 'https://images.unsplash.com/photo-1633465784393-d0c2da7cc9cf?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=687&q=80'
    const navigation = useNavigation();
    const joyus = 'https://images.unsplash.com/photo-1604537529428-15bcbeecfe4d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2069&q=80'
    const ourOrange = '#FF4F00';

    const scrollX = new Animated.Value(0);
    let position  = Animated.divide(scrollX, width)
    const [userInfo, setUserInfo] = useState();

    const [banners, setBanners] = useState([]);
    const [secOne, setSecOne] = useState([]);
    const [secTwo, setSecTwo] = useState([]);
    const [secThree, setSecThree] = useState([]);

    const [data, setData] = useState(null);

    const checkAuth = async()=>{


      try {
        await Auth.currentAuthenticatedUser().then((ef)=> {
          return
        })
      } catch (error) {
        console.error('ssb',error),
        setAuthed(false)
        return
      }



      const userAuthenticated =  await Auth.currentAuthenticatedUser();
      const userSub = userAuthenticated.attributes.sub;

      
      try{
        await API.graphql(graphqlOperation(getUserData, { id: userSub})).then((ef)=> {
        const userd = ef.data.getUserData

          setAuthedUser({
            email: userd.email,
            firstName: userd.firstName
          })

        setUserInfo(userd),
        setAuthed(true)

        return
      }).catch((ee)=> {
        console.error(ee),
        setAuthed(false)
      })

  } catch (e){
    console.error(e),
    setAuthed(false),
    console.log('Not authenticated')
  }

    }

  const listingBanners = async() => {
      

    const allTodos = await API.graphql({query: queries.listCampaigns, variables: 
        {
        filter : { and: [ 
            {active: {eq: true}},
            {approved: {eq: true}},
            {banner: {eq: true}},
            
        ]}
    } });
    
    const todos = allTodos.data.listCampaigns.items
    setBanners(todos)
    

    const allSecone = await API.graphql({query: queries.listCampaigns, variables: 
      {
      filter : { and: [ 
          {active: {eq: true}},
          {approved: {eq: true}},
          {secOne: {eq: true}},
          
      ]}
  } });
  
  const seconedata = allSecone.data.listCampaigns.items
  setSecOne(seconedata)


  const allSecTwo = await API.graphql({query: queries.listCampaigns, variables: 
    {
    filter : { and: [ 
        {active: {eq: true}},
        {approved: {eq: true}},
        {secTwo: {eq: true}},
        
    ]}
} });

const secTwodata = allSecTwo.data.listCampaigns.items
setSecTwo(secTwodata)


const allSecThree = await API.graphql({query: queries.listCampaigns, variables: 
  {
  filter : { and: [ 
      {active: {eq: true}},
      {approved: {eq: true}},
      {secThree: {eq: true}},
      
  ]}
} });

const secThreedata = allSecThree.data.listCampaigns.items
setSecThree(secThreedata)



  }


  // console.log(JSON.stringify('sss '+datab.linkdirect))
  
  
  // if(datar){
  //   console.log('homee', datar.route)
  // }

  // const handleDeepLink = (event) => {
  //   let dataa = Linking.parse(event.url)
  //   setData(dataa)

  //   console.log(data && JSON.stringify('home',data.path))  

  // }

  
  // Linking.addEventListener("url", handleDeepLink)


  useEffect(async() => {
    
   
    console.log(data && JSON.stringify('home',data.path))

    if(datab?.linkdirect){
      navigation.navigate('EventTicketsLink', {id: datab?.linkdirect})
      }

  }, [data])


  useEffect(() => {
    checkAuth()
    listingBanners()
       
  }, [userInfo])




  const categoryList = [
    {
      id: 1,
      name: 'Comedy',
      image: 'https://images.unsplash.com/photo-1543584756-8f40a802e14f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80'
    },
    {
      id: 2,
      name: 'Festival',
      image: 'https://images.unsplash.com/photo-1506157786151-b8491531f063?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80'
    },
    {
      id: 3,
      name: 'Music',
      image: 'https://images.unsplash.com/photo-1484972759836-b93f9ef2b293?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80'
    },
    {
      id: 4,
      name: 'Church',
      image: 'https://images.unsplash.com/photo-1548625149-fc4a29cf7092?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1064&q=80'
    },
    {
      id: 5,
      name: 'Fashion',
      image: 'https://images.unsplash.com/photo-1515886657613-9f3515b0c78f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1020&q=80'
    },
    {
      id: 6,
      name: 'Bus',
      image: 'https://images.unsplash.com/photo-1544620347-c4fd4a3d5957?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2069&q=80'
    },
    {
      id: 7,
      name: 'Soccer',
      image: 'https://images.unsplash.com/photo-1560272564-c83b66b1ad12?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1049&q=80'
    },
    {
      id: 8,
      name: 'Rugby',
      image: 'https://images.unsplash.com/photo-1512299286776-c18be8ed6a1a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80'
    },{
      id: 9,
      name: 'Cricket',
      image: 'https://images.unsplash.com/photo-1593341646782-e0b495cff86d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=987&q=80'
    },{
      id: 10,
      name: 'Kids',
      image: 'https://images.unsplash.com/photo-1593085512500-5d55148d6f0d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1480&q=80'
    },{
      id: 11,
      name: 'Lifestyle',
      image: 'https://images.unsplash.com/photo-1541904845547-0eaf866de232?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=941&q=80'
    },{
      id: 12,
      name: 'Theatre',
      image: 'https://images.unsplash.com/photo-1514306191717-452ec28c7814?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2069&q=80'
    },{
      id: 13,
      name: 'Tourism',
      image: 'https://images.unsplash.com/photo-1519922639192-e73293ca430e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2072&q=80'
    },{
      id: 14,
      name: 'Faith',
      image: 'https://images.unsplash.com/photo-1600468448005-67286e81341b?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=987&q=80'
    },{
      id: 15,
      name: 'Seminar',
      image: 'https://images.unsplash.com/photo-1587825140708-dfaf72ae4b04?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80'
    },{
      id: 16,
      name: 'Workshop',
      image: 'https://images.unsplash.com/photo-1659301254614-8d6a9d46f26a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80'
    },{
      id: 17,
      name: 'Charity',
      image: 'https://images.unsplash.com/photo-1488521787991-ed7bbaae773c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80'
    },{
      id: 18,
      name: 'Community',
      image: 'https://images.unsplash.com/photo-1582213782179-e0d53f98f2ca?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80'
    },{
      id: 19,
      name: 'Health',
      image: 'https://images.unsplash.com/photo-1532938911079-1b06ac7ceec7?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2832&q=80'
    }
  ]





    const renderBanner = ({item}) => {
      return(
    <ImageBackground imageStyle={{borderRadius: 0}} style={{width: width, elevation: 0, height: height / 1.5, borderRadius: 0}}   source={{uri: item?.image}} >
          <LinearGradient  
                style={{width: '100%', height: '100%', alignItems: 'center' , justifyContent: 'center'}}
                colors={['rgba(0,0,0,0.3)', 'rgba(0,0,0,0.5)', 'rgba(0,0,0,0.6)']}>

                  <Animated.View style={{borderRadius: 10, backgroundColor: 'rgba(0,0,0,0.6)', width: width / 1.5, padding: 10, alignItems: 'center'}}>
                      <Text style={{fontWeight: 'bold', fontSize: 20, color: '#fcfcfc', alignSelf: 'center', textAlign: 'center'}}>{item?.name}</Text>
                      <View style={{flexDirection: 'row', alignSelf: 'center', alignItems: 'center', marginTop: 10}}>
                        <Feather name= 'info' color={'white'} size={15} />
                        <Text style={{fontSize: 12, color: '#fcfcfc', marginLeft: 10}}>FROM R{Number(item?.minAmount).toFixed(2)}</Text>
                      </View>

                  
                      <Animated.View style={{height: 30, width: 100, backgroundColor: ourOrange, alignItems: 'center', justifyContent: 'center', borderRadius: 5, marginTop: 15, marginBottom: 25}}>
                        <TouchableOpacity onPress={()=> navigation.navigate('EventDetails', {item: item})} style={{flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center'}}>
                          <Text style={{fontSize: 12, fontWeight: 'bold', color: '#fcfcfc', marginRight: 5}}>See Event</Text>
                          <Feather name='arrow-right' size={10} color={'#fcfcfc'} />
                        </TouchableOpacity>
                      </Animated.View>

                  </Animated.View>

                  

                </LinearGradient>
     </ImageBackground>
      )
    }


    const showBanner = () => {

      return(
        <View>
          <FlatList
                    data={banners}
                    keyExtractor={(item, index) => 'key'+index}
                    horizontal
                    pagingEnabled
                    scrollEnabled
                    snapToAlignment='center'
                    scrollEventThrottle={16}
                    decelerationRate={"normal"}
                    showsHorizontalScrollIndicator={false}
                    renderItem={renderBanner}
                    onScroll = {Animated.event(
                        [{nativeEvent: {contentOffset: {x: scrollX}}},
                        
                        ], {useNativeDriver: false}
                    )}


                 />

                 <Animated.View style={[styles.dotView,{marginTop: 15, backgroundColor: 'rgba(0,0,0,0.9)', position: 'absolute', zIndex: 9000, top: 40, right: 20}]}>
                    {banners.map((_, i) => {
                      let opacity = position.interpolate({
                            inputRange: [i - 1, i, i + 1],
                            outputRange: [0.2, 1, 0.2],
                            extrapolate: 'clamp'
                        })

                        return(
                            <Animated.View key={i}
                            style={{opacity, height: 7, width: 7, backgroundColor: 'black', margin: 8, borderRadius: 5, marginTop: -30}}
                              />
                        )
                    })}
                 </Animated.View>

                 </View>
      )
      

    }

    const imageCat = 'https://images.unsplash.com/photo-1489549132488-d00b7eee80f1?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=687&q=80'

    const renderEventList = ({item})=> {
      return(
        <TouchableOpacity style={{marginRight: 10}} onPress={()=> navigation.navigate('EventDetails', {item: item})}>
            <ImageBackground source={{uri: item.image}} imageStyle={{borderRadius: 5}} style={{width: width/2.5, height: width / 2.5}}>

            </ImageBackground>
            <Text style={{fontSize: 12, marginTop: 10}}>{String(item.name).slice(0,25)}</Text>
            {item?.minAmount > 0 ? 
             <Text style={{fontSize: 12, color: 'gray'}}>From R{Number(item.minAmount).toFixed(2)}</Text> :
             <Text style={{fontSize: 12, color: 'gray'}}>FREE</Text>
          }
           
        </TouchableOpacity>
      )
    }


    const renderCategory = ({item})=> {
      return(
        <TouchableOpacity onPress={()=> navigation.navigate('CategorySearch', {cat: item.name})} style={{height: width / 3, marginRight: 15, width: width / 3.5, borderWidth: 0, alignItems: 'center', justifyContent: 'center'}}>
          <View style={{height: width/3 - 30, width: '100%'}}>
            <Image source={{uri: item?.image}} style={{height: '100%', width: '100%', borderRadius: 10}} />
          </View>
          <Text style={{fontSize: 12, marginTop: 5}}>{item.name}</Text>
        </TouchableOpacity>
      )
    }



  return (
    <View style={{flex: 1, backgroundColor: '#fcfcfc'}}>
        <View style={{zIndex: 9000, position:'absolute'}}>
            <Header/>
        </View>
        <ScrollView style={{height: height}}>
        {/* hero */}
        <View style={{height: height/1.5, width: width, marginTop: 60}}>
          {showBanner()}
        </View>
       


        <View style={{height: 20, width: width, backgroundColor: '#edf1f2'}} />
       
        {/* popular categories */}
        
        <Animated.View style={{}}>
          <Text style={{fontWeight: 'bold', fontSize: 18, marginLeft: 15, marginTop: 25}}>Popular Categories</Text>

          <Animated.View style={{marginTop: 20}}>

            <FlatList
              horizontal
              showsHorizontalScrollIndicator={false}
              style={{paddingLeft: 25}}
              data={categoryList}
              renderItem={renderCategory}
            />


         
          </Animated.View>



        </Animated.View>

        <Animated.View style={{height: height / 3, width: width - 30, backgroundColor: '#edf1f2', alignSelf: 'center', padding: 10, alignItems: 'center', justifyContent: 'center', borderRadius: 20, marginTop: 30}}>
          <ImageBackground source={{uri: joyus}} style={{height: '100%', width: '100%'}} imageStyle={{borderRadius: 20}}>
          <LinearGradient  
                style={{width: '100%', height: '100%', alignItems: 'center' , borderRadius: 20, justifyContent: 'center'}}
                colors={['rgba(0,0,0,0.3)', 'rgba(0,0,0,0.5)', 'rgba(0,0,0,0.6)']}>

                  <Animated.View style={{borderRadius: 10, backgroundColor: 'rgba(0,0,0,0.6)', width: width / 1.5, padding: 10, alignItems: 'center'}}>
                      <Text style={{fontWeight: 'bold', fontSize: 20, color: '#fcfcfc', alignSelf: 'center', textAlign: 'center'}}>Joyus Celebration One Night</Text>
                      <View style={{flexDirection: 'row', alignSelf: 'center', alignItems: 'center', marginTop: 10}}>
                        <Feather name='map' color={'white'} size={15} />
                        <Text style={{fontSize: 12, color: '#fcfcfc', marginLeft: 20}}>Matsulu Community Hall</Text>
                      </View>

                  
                      <Animated.View style={{height: 30, width: 100, backgroundColor: ourOrange, alignItems: 'center', justifyContent: 'center', borderRadius: 5, marginTop: 15, marginBottom: 25}}>
                        <TouchableOpacity style={{flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center'}} onPress={()=> navigation.navigate('EventDetails', {})}>
                          <Text style={{fontSize: 12, fontWeight: 'bold', color: '#fcfcfc', marginRight: 5}}>See Event</Text>
                          <Feather name='arrow-right' size={10} color={'#fcfcfc'} />
                        </TouchableOpacity>
                      </Animated.View>

                  </Animated.View>

                  

                </LinearGradient>
          </ImageBackground>
        </Animated.View>

        <Animated.View style={{width :width}}>

            <Text style={{fontWeight: 'bold', fontSize: 18, marginLeft: 15, marginTop: 25}}>Trending in South Africa  <Image source={{uri: 'https://em-content.zobj.net/thumbs/160/whatsapp/116/flag-for-south-africa_1f1ff-1f1e6.png'}} style={{height: 20, width: 20}}/> </Text>
       
          <FlatList 
            showsHorizontalScrollIndicator={false}
            horizontal
            data={secOne}
            renderItem={renderEventList}
            style={{marginTop: 15, paddingLeft: 15, marginBottom: 10}}
          />
       
       
       
       
        </Animated.View>

        <Animated.View style={{width :width}}>

            <Text style={{fontWeight: 'bold', fontSize: 18, marginLeft: 15, marginTop: 25}}>Weekend Vibes<Image source={{uri: 'https://em-content.zobj.net/source/microsoft-teams/363/man-dancing_1f57a.png'}} style={{height: 30, width: 30}}/> </Text>
       
          <FlatList 
            showsHorizontalScrollIndicator={false}
            horizontal
            data={secTwo}
            renderItem={renderEventList}
            style={{marginTop: 15, paddingLeft: 15, marginBottom: 15}}
          />
       
       
       
       
        </Animated.View>

        <Animated.View style={{height: height / 3, width: width - 30, backgroundColor: '#edf1f2', alignSelf: 'center', padding: 10, alignItems: 'center', justifyContent: 'center', borderRadius: 20, marginTop: 30}}>
          <ImageBackground source={{uri: joyus}} style={{height: '100%', width: '100%'}} imageStyle={{borderRadius: 20}}>
          <LinearGradient  
                style={{width: '100%', height: '100%', alignItems: 'center' , borderRadius: 20, justifyContent: 'center'}}
                colors={['rgba(0,0,0,0.3)', 'rgba(0,0,0,0.5)', 'rgba(0,0,0,0.6)']}>

                  <Animated.View style={{borderRadius: 10, backgroundColor: 'rgba(0,0,0,0.6)', width: width / 1.5, padding: 10, alignItems: 'center'}}>
                      <Text style={{fontWeight: 'bold', fontSize: 20, color: '#fcfcfc', alignSelf: 'center', textAlign: 'center'}}>List your event</Text>
                      <View style={{flexDirection: 'row', alignSelf: 'center', alignItems: 'center', marginTop: 10}}>
                        <Text style={{fontSize: 12, color: '#fcfcfc', marginLeft: 0}}>Create your event listing for free</Text>
                      </View>

                      <View>
                        
                      <Animated.View style={{height: 30, width: 150, backgroundColor: ourOrange, alignItems: 'center', justifyContent: 'center', borderRadius: 5, marginTop: 15, marginBottom: 0}}>
                        <TouchableOpacity style={{flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center'}} onPress={()=> navigation.navigate('EventDetails', {})}>
                          <Text style={{fontSize: 12, fontWeight: 'bold', color: '#fcfcfc', marginRight: 5}}>Watch Video</Text>
                          <Feather name='arrow-right' size={10} color={'#fcfcfc'} />
                        </TouchableOpacity>
                      </Animated.View>
                      
                      <Animated.View style={{height: 30, width: 150, backgroundColor: 'transparent', borderWidth: 1, borderColor: ourOrange, alignItems: 'center', justifyContent: 'center', borderRadius: 5, marginTop: 10, marginBottom: 25}}>
                        <TouchableOpacity 
                        style={{flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center'}}
                         onPress={()=> navigation.navigate('FormEvent', {})}>
                          <Text style={{fontSize: 12, fontWeight: 'bold', color: ourOrange, marginRight: 5}}>Sell Event</Text>
                          <Feather name= 'plus' size={10} color={ourOrange} />
                        </TouchableOpacity>
                      </Animated.View>
                      
                      </View>

                  </Animated.View>

                  

                </LinearGradient>
          </ImageBackground>
        </Animated.View>

        <Animated.View style={{width :width}}>

            <Text style={{fontWeight: 'bold', fontSize: 18, marginLeft: 15, marginTop: 25}}>You might like<Image source={{uri: 'https://em-content.zobj.net/source/microsoft-teams/363/man-dancing_1f57a.png'}} style={{height: 30, width: 30}}/> </Text>
       
          <FlatList 
            showsHorizontalScrollIndicator={false}
            horizontal
            data={secThree}
            renderItem={renderEventList}
            style={{marginTop: 15, paddingLeft: 15, marginBottom: 0}}
          />
       
       
       
       
        </Animated.View>

        {/* <Animated.View style={{width :width}}>

        <Text style={{fontWeight: 'bold', fontSize: 18, marginLeft: 15, marginTop: 25}}>Gamer's Paradise<Image source={{uri: 'https://em-content.zobj.net/source/microsoft-teams/363/man-dancing_1f57a.png'}} style={{height: 30, width: 30}}/> </Text>

        <FlatList 
        showsHorizontalScrollIndicator={false}
        horizontal
        data={eventList}
        renderItem={renderEventList}
        style={{marginTop: 15, paddingLeft: 15, marginBottom: 0}}
        />




        </Animated.View>

        <Animated.View style={{width :width}}>

<Text style={{fontWeight: 'bold', fontSize: 18, marginLeft: 15, marginTop: 25}}>Gauteng<Image source={{uri: 'https://em-content.zobj.net/source/microsoft-teams/363/man-dancing_1f57a.png'}} style={{height: 30, width: 30}}/> </Text>

<FlatList 
showsHorizontalScrollIndicator={false}
horizontal
data={eventList}
renderItem={renderEventList}
style={{marginTop: 15, paddingLeft: 15, marginBottom: 15}}
/>




        </Animated.View> */}

        <Animated.View style={{height: height / 3, width: width, backgroundColor: '#1e1f24', marginTop: 25, padding: 25}}>
            <View style={{}}>
              <Image source={require('./assets/nblogo.png')} style={{width: 80, height: 60, resizeMode: 'contain'}} />
            </View>

            <View style={{flexDirection: 'row', justifyContent: 'space-evenly'}}>
              <TouchableOpacity 
              onPress={()=> navigation.navigate('AboutUs')}
              style={{width: '35%'}}>
                <Text style={{fontSize: 12, color: '#fcfcfc'}}>About Us</Text>
              </TouchableOpacity>

              <TouchableOpacity 
              onPress={()=> navigation.navigate('listevent')}
              style={{width: '35%'}}>
                <Text style={{fontSize: 12, color: '#fcfcfc'}}>List your event</Text>
              </TouchableOpacity>

              <TouchableOpacity onPress={()=> navigation.navigate('help')} style={{width: '35%'}}>
                <Text style={{fontSize: 12, color: '#fcfcfc'}}>Help</Text>
              </TouchableOpacity>
            </View>

            <View style={{flexDirection: 'row', marginTop: 25, justifyContent: 'space-evenly'}}>
              <TouchableOpacity onPress={()=> navigation.navigate('faqs')} style={{width: '35%'}}>
                <Text style={{fontSize: 12, color: '#fcfcfc'}}>FAQs</Text>
              </TouchableOpacity>

              <TouchableOpacity 
              onPress={()=> navigation.navigate('TsnCs')}
              style={{width: '35%'}}>
                <Text style={{fontSize: 12, color: '#fcfcfc'}}>Ts n Cs</Text>
              </TouchableOpacity>

              <TouchableOpacity
              onPress={()=> navigation.navigate('PrivacyPolicy')}
              style={{width: '35%'}}>
                <Text style={{fontSize: 12, color: '#fcfcfc'}}>Privacy Policy</Text>
              </TouchableOpacity>
            </View>

            <View style={{flexDirection: 'row', marginTop: 25, justifyContent: 'space-evenly'}}>
              <TouchableOpacity style={{width: '35%'}}>
                <Text style={{fontSize: 12, color: '#fcfcfc'}}>Chat to us</Text>
              </TouchableOpacity>

              <TouchableOpacity style={{width: '35%'}}>
                <Text style={{fontSize: 12, color: '#fcfcfc'}}>Find Us</Text>
              </TouchableOpacity>

              <TouchableOpacity onPress={()=> navigation.navigate('Downloads')} style={{width: '35%'}}>
                <Text style={{fontSize: 12, color: '#fcfcfc'}}>Download Apps</Text>
              </TouchableOpacity>
            </View>


            <View style={{flexDirection: 'row', marginTop: 25, alignItems: 'center', justifyContent: 'center'}}>
              {/* <Pressable 
                style={{marginLeft: 0}}
                onPress={()=> Linking.openURL('http://www.youtube.com/ticketdaysa')}>
                <Ionicons name= 'logo-youtube' size={20} color={'#fcfcfc'} />
              </Pressable> */}

              <Pressable 
                style={{marginLeft: 0}}
                onPress={()=> Linking.openURL('https://www.facebook.com/profile.php?id=100089071520948')}>
                <Ionicons name= 'logo-facebook' size={20} color={'#fcfcfc'} />
              </Pressable>


              <Pressable 
                style={{marginLeft: 15}}
                onPress={()=> Linking.openURL('https://instagram.com/ticketdaysa?igshid=MzRlODBiNWFlZA==')}>
                <Ionicons name= 'logo-instagram' size={20} color={'#fcfcfc'} />
              </Pressable>

              <Pressable 
                style={{marginLeft: 15}}
                onPress={()=> Linking.openURL('https://twitter.com/TicketdaySA?s=20')}>
                <Ionicons name= 'logo-twitter' size={20} color={'#fcfcfc'} />
              </Pressable>

              <Pressable 
                style={{marginLeft: 15}}
                onPress={()=> Linking.openURL('https://www.tiktok.com/@ticketdaysa?_t=8fUc21OYfIs&_r=1')}>
                <FontAwesome5 name= 'tiktok' size={20} color={'#fcfcfc'} />
              </Pressable>
            </View>
            
            <Text style={{fontSize: 10, color: '#fcfcfc', alignSelf: 'center', marginTop: 20}}>Developed by Sunday Creatives (Pty) Ltd</Text>
        </Animated.View>

        </ScrollView>


    </View>
  )
}

export default HomeMain

const styles = StyleSheet.create({
  dotView: {
      flexDirection: 'row',
      justifyContent: 'flex-end',
  }
})