import { StyleSheet, Text, View } from 'react-native'
import React from 'react';
import Header from '../Components/Header';
import EmptyComp from '../Components/EmptyComp';

const Profile = () => {
  return (
    <View style={{flex: 1}}>
        <Header/>
      <Text>Profile</Text>
      <EmptyComp/>
    </View>
  )
}

export default Profile

const styles = StyleSheet.create({})