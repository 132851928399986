import { View, Text, Animated, TouchableOpacity, ImageBackground, Dimensions, ScrollView } from 'react-native'
import React from 'react'
import Header from '../Components/Header';
import { useNavigation, useRoute } from '@react-navigation/native';
import { Feather, FontAwesome, Ionicons, AntDesign, MaterialIcons, MaterialCommunityIcons, Entypo } from '@expo/vector-icons'; 

const {width, height} = Dimensions.get('screen')

const TsnCs = () => {

    const navigation = useNavigation();

    const ourOrange = '#FF4F00';
    const headImg = 'https://images.unsplash.com/photo-1674574124792-3be232f1957f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80'

  return (
    <View style={{flex: 1, backgroundColor: '#fcfcfc'}}>
        <Animated.View style={{flexDirection: 'row', height: 100, width: width}}>
        <ImageBackground source={{uri: headImg}} style={{height: '100%', width: width}}>
        <Animated.View style={{position: 'absolute', top: 0, left: 0, zIndex: 9000}}>
            <TouchableOpacity onPress={()=> navigation.goBack()} style={{height: 60, width: 60, backgroundColor: ourOrange, alignItems: 'center', justifyContent: 'center'}}>
                <Feather name='arrow-left' size={30} color={'#fcfcfc'}  />
            </TouchableOpacity>
        </Animated.View>
        <View style={{height: '100%', width: '100%', backgroundColor: 'black', opacity: 0.4}}>

        </View>

        </ImageBackground>

    

        </Animated.View>

        <ScrollView>
            
        <Animated.View style={{padding: 30}}>
            <Text style={{alignSelf: 'center', fontWeight: 'bold', fontSize: 30}}>Terms Of Service</Text>

            <View style={{width: width /2, alignSelf: 'center', marginTop: 30}}>
                
            <Text style={{fontSize: 18, fontWeight: 'bold'}}>Home Terms</Text>
            <Text style={{textAlign: 'justify', marginTop: 10}}>
            Ticketday maintains this web site (the "Ticketday.co.za Site") as a service to: its visitors, its
customers who create tickets booking using Ticketday ("Customers") . Please review the following
terms and conditions carefully. This Terms of Use Agreement (the "Terms of Use") is a legally binding
contract between each User and Ticketday regarding Users' access to and use of Ticketday Site and
Customer's tickets (collectively, the "Sites"). By accessing the Sites, the User agrees to the terms and
conditions as outlined in this legal notice. Ticketday reserves the right to change these terms and
conditions from time to time at its sole discretion by posting such revised terms and conditions on
the Ticketday Site. User's continued use of the Sites following any such change constitutes an
agreement to follow and be bound by the terms and conditions as changed. If a User does not agree
with any part of the following terms and conditions, she/he must not use the Sites. Customers
hereby undertake to post this Terms of Use on their websites at all times.
            </Text>
            
          






            </View>

            <View style={{width: width /2, alignSelf: 'center', marginTop: 30}}>
                
            <Text style={{fontSize: 18, fontWeight: 'bold'}}>1. Ownership</Text>
            <Text style={{textAlign: 'justify', marginTop: 10}}>
            The Sites are the property of Ticketday its licensors and/or its various third party providers and
            distributors and as the case may be, its Customers and are protected by copyright, trademark and
            other intellectual property laws. Users must retain all copyright and trademark notices, including any
            other proprietary notices, contained in the materials, and Users agree not to alter, obscure or
            obliterate any of such notices. Unless otherwise set forth in this Terms of Use, none of the content
            or data found in the Site may be copied, reproduced, republished, sold, transferred, modified or
            distributed in any way without the prior written consent of Ticketday its licensors and/or its third
            party providers and distributors. Trademarks, logos, images and service marks displayed on the Sites
            are the property of either Ticketday or other third parties. Users agree not to display or use such
            marks without prior written consent of Ticketday
            </Text>



            </View>

            <View style={{width: width /2, alignSelf: 'center', marginTop: 30}}>
                
            <Text style={{fontSize: 18, fontWeight: 'bold'}}>2. Use of site</Text>
            <Text style={{textAlign: 'justify', marginTop: 10}}>
            Users may only use the Sites for legitimate purposes and shall not use the Sites to make any
            speculation, false or fraudulent representations, including but not limited to speculative, false or
            fraudulent reservations or reservations in anticipations of demand. Users may not use any "robot",
            spider" or other automatic devices on a program, algorithm on methodology having similar
            processes or functionality, or to monitor or copy any web pages, date or content found on the Sites,
            in any case without the prior written consent of Ticketday
            </Text>



            </View>

            <View style={{width: width /2, alignSelf: 'center', marginTop: 30}}>
                
            <Text style={{fontSize: 18, fontWeight: 'bold'}}>3. Registration, Accounts and Data.</Text>
            <Text style={{textAlign: 'justify', marginTop: 10}}>
            The Services and related features, such as personalization, ticket management, real-time report on
tickets booking services made available may require registration. Should a User choose to register
for such Services or related features, she/he agrees to provide accurate and current information
about her/him as required by the relevant registration process, and to promptly update such
information as necessary to ensure that it is kept accurate and complete. If at any time the User
requests that Ticketday pays out the funds owed to him/her, he/she shall provide Ticketday with
accurate bank account information. Users agree to be responsible for: (a) maintaining the
confidentiality of any passwords or other account identifiers chosen by Users or are assigned as a
result of any registration, and (b) all activities that occur under such password or account. Further,
Users agree to notify Ticketday of any unauthorized use of their password or account. In addition
and notwithstanding the foregoing, should a party other than the User register as the contact person
in charge of collecting the money in the context of an event (the "Treasurer") using the User's
password or account information, the User and the Treasurer each understand and agree that
Ticketday will presume that the Treasurer is an authorized agent of the User and that it shall remit
the money collected directly to such Treasurer and in no event shall Ticketday be requested to
provide the collected funds for such Services to any other party other than the Treasurer unless the
User has instructed Ticketday in writing to do so prior to Ticketday having already remitted the
collected funds to the Treasurer, in which case Ticketday shall remit the funds directly to the User.
Ticketday SHALL NOT BE RESPONSIBLE OR LIABLE, DIRECTLY OR INDIRECTLY, IN ANY WAY FOR ANY
LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF, OR IN CONNECTION WITH, USERS'
FAILURE TO COMPLY WITH THIS SECTION 5 OR IN CONNECTION WITH Ticketday HAVING REMITTED
THE COLLECTED FUNDS TO THE TREASURER OR THE USER IN ACCORDANCE WITH THIS SECTION 3
 
            </Text>



            </View>

            <View style={{width: width /2, alignSelf: 'center', marginTop: 30}}>
                
            <Text style={{fontSize: 18, fontWeight: 'bold'}}>4. Rules and Conduct</Text>
            <Text style={{textAlign: 'justify', marginTop: 10}}>
            In consideration of the availability, and the use of the Ticketday services (the "Services"), each User
agrees, while visiting the Sites, not to: (a) post, transmit, link to, or otherwise distribute any
information constituting or encouraging conduct that would constitute a criminal offense or give rise
to civil liability, or otherwise use the Sites or any of the Services in a manner which is contrary to law
or would serve to restrict or inhibit any other user from using or enjoying the Sites, the Services or
the Internet; (b) defame, abuse, stalk, harass, threaten or otherwise violate the legal rights of others,
including without limitation, rights relating to privacy and publicity; (c) post, transmit, link to, or
otherwise distribute any inappropriate, profane, defamatory, infringing, obscene, indecent or
unlawful material or information; (d) post, transmit, link to, or otherwise distribute any information
or software which contains a virus, cancelbot, Trojan horse, worm or other harmful or disruptive
component; (e) upload, post, publish, transmit, reproduce, link to or otherwise distribute in any way,
information, software or other material obtained through the Sites or the Services, or any derivative
work thereof, which is protected by copyright, or any other proprietary or intellectual property right,
without obtaining permission of the copyright owner or right holder; (f) impersonate of falsely
represent the association of a User with any person, including a Ticketday representative or site
moderator; and (g) use, reproduce, sell, resell or otherwise exploit the Sites or any of the Services for
any commercial purposes. If a third party requests that Ticketday remove content from the Sites that
in any way represents or refer to that third party, Ticketday reserves the right to remove the content
of that individual or group without any liability whatsoever.

            </Text>



            </View>

            <View style={{width: width /2, alignSelf: 'center', marginTop: 30}}>
                
            <Text style={{fontSize: 18, fontWeight: 'bold'}}>5. Pay Services.</Text>
            <Text style={{textAlign: 'justify', marginTop: 10}}>
            Certain of the Services made available over the Ticketday Site may be offered by Ticketday on a
payment basis (hereinafter referred to as a "Pay Service"), which Pay Service may be offered by
Ticketday on a "prepaid" or on a "pay as you use" basis. Should a User choose to have access to a
Pay Services, she/he will be required to register as described in Section 3 above, and such User will
be assigned an account for such Pay Service (the "Pay Service Account"). Each User agrees to pay all
the fees applicable to such Pay Service, including any fees associated with the use of such Pay
Service and any Ticketday payment service fees listed on the pricing page together with all
applicable taxes (collectively, the "Fees"). Unless otherwise indicated on the relevant Pay Service
description page (the "Pay Service Description"), all Fees for a Pay Service will be billed immediately
to the balance account of such User and are non-refundable. Ticketday reserves the right to change
the amount of, or basis for determining, any Fees for a Pay Service, and to institute new Fees or
terms at any time effective upon notice to Users, which notice may for certainty include the posting
of any such changes on the relevant pages of the Ticketday Site related to the use of the Pay
Services. In connection with the foregoing, each User warrants that: (a) she/he is of the age of
majority in the jurisdiction in which she/he reside, (b) all information she/he submits is true and
correct (including without limitation all credit/debit card information), and (c) she/he is the
cardholder of the credit/debit card designated by the User for the Pay Service Account

 
            </Text>



            </View>

                

            <View style={{width: width /2, alignSelf: 'center', marginTop: 30}}>
                
            <Text style={{fontSize: 18, fontWeight: 'bold'}}>6. Acknowledgement</Text>
            <Text style={{textAlign: 'justify', marginTop: 10}}>
            In consideration of the availability, and use by the User, of a Pay Service, Users acknowledge and
agree: (a) to the Terms of use , including without limitation the disclaimers, limitation of liability and
provisions related to prohibited conduct set out herein as well as any terms and conditions which
may be set out in the relevant Pay Service Description, (b) that Users are solely responsible for all
acts or omissions that occur through their Pay Service Account, and (c) that Ticketday reserves the
right to suspend or terminate the Pay Service Account of a User and her/his use of a Pay Service at
any time, including without limitation, where Ticketday has determined, in its sole discretion, that
the use of the Pay Service by a User is, or was, in breach of the Terms of use . Any such termination
by Ticketday shall be in addition to and without prejudice to such rights and remedies as may be
available to Ticketday including injunction and other equitable remedies. In certain cases Ticketday
may require to hold funds as a reserve if an event poses what it deems as additional risk

            </Text>



            </View> 

            <View style={{width: width /2, alignSelf: 'center', marginTop: 30}}>
                
                <Text style={{fontSize: 18, fontWeight: 'bold'}}>7. Dealing with Third Parties</Text>
                <Text style={{textAlign: 'justify', marginTop: 10}}>
                At certain places on the Sites, live "links" to other Internet addresses can be accessed. Such external
Internet addresses contain information created, published, maintained, or otherwise posted by
individuals, institutions, or organizations independent of Ticketday. Ticketday does not endorse,
approve, certify, or control these external Internet addresses and does not guarantee the accuracy,
completeness, efficiency, timeliness, or correct sequencing of information located at such addresses.
Use of any information obtained from such addresses is voluntary, and reliance on it should only be
undertaken after an independent review of its accuracy, completeness, efficiency and timeliness.
Reference herein to any specific commercial product, process, or service by trade name, trademark,
service mark, manufacturer, or otherwise does not constitute or imply endorsement,
recommendation, or favouring by Ticketday The sites links from the Sites are not under Ticketday
control, and Ticketday does not assume any responsibility or liability for any communications or
materials available at such linked sites. Ticketday does not intend links on the Sites to be
endorsements of the linked entities, and are provided for convenience only. Users acknowledge and
agree that their correspondence or business dealings with any third parties, including any merchants
or advertisers, found on, or through, the Sites or any of the Services, including payment for and
delivery of related goods and services, and all other terms, conditions, representations and
warranties related to such dealings, are solely as between Users and such third parties. Ticketday
ASSUMES NO RESPONSIBILITY WHATSOEVER FOR ANY CHARGES THAT USERS INCUR WHEN MAKING
PURCHASES OR OTHER TRANSACTION IN THIS MANNER. Furthermore, the responsibility for ensuring
compliance with all applicable laws in connection with any such transactions shall be the User's
alone. USERS AGREE THAT Ticketday SHALL NOT BE RESPONSIBLE OR LIABLE, DIRECTLY OR
INDIRECTLY, IN ANY WAY FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF, OR
IN CONNECTION WITH, ANY OF SUCH DEALINGS OR TRANSACTIONS.
     
                </Text>
    
    
    
            </View>    

            <View style={{width: width /2, alignSelf: 'center', marginTop: 30}}>
                
            <Text style={{fontSize: 18, fontWeight: 'bold'}}>8. Exclusion of Warranty</Text>
            <Text style={{textAlign: 'justify', marginTop: 10}}>
            THE SITES, AND THE CONTENTS THEREIN AND/OR THE CONTENT PROVIDED BY Ticketday ARE
PROVIDED "AS IS" AND "AS AVAILABLE", AND ALL WARRANTIES, EXPRESS OR IMPLIED, ARE
DISCLAIMED, INCLUDING BUT NOT LIMITED TO, ANY IMPLIED WARRANTIES OF MERCHANTABILITY,
QUIET ENJOYMENT, NON-INFRINGEMENT AND FITNESS FOR A PARTICULAR PURPOSE. Ticketday IS
NOT RESPONSIBLE FOR (A) THE ACCURACY OF ANY CONTENT ON THE SITES, OR (B) FOR DAMAGES
OR LOSS CAUSED BY THE INTERNET, ERRORS OF THE SITES, OR CONTENT ON THE SITES, Ticketday
DOES NOT EXERCISE CONTROL OVER ANY THIRD PARTIES THAT PROVIDE CONTENT TO THE SITES
AND THEREFORE PROVIDES NO REPRESENTATIONS AND WARRANTIES REGARDING SAME.
FURTHERMORE, USERS EXPRESSLY UNDERSTAND AND AGREE THAT Ticketday MAKES NO
REPRESENTATIONS OR WARRANTIES THAT THE SITES OR ANY OF THE SERVICES, INCLUDING ANY
CONTENT, INFORMATION, PRODUCTS OR SERVICES OBTAINED FROM OR THROUGH THE USE OF THE
SITES OR THE SERVICES, WILL BE PROVIDED ON AN UNINTERRUPTED, TIMELY, SECURE OR ERRORFREE BASIS OR THAT SUCH SERVICES OR THE RESULTS DERIVED THEREFROM WILL MEET USERS'
REQUIREMENTS OR EXPECTATIONS
            </Text>



            </View> 

            <View style={{width: width /2, alignSelf: 'center', marginTop: 30}}>
                
            <Text style={{fontSize: 18, fontWeight: 'bold'}}>9. Limitation of Liability</Text>
            <Text style={{textAlign: 'justify', marginTop: 10}}>
            Ticketday AND ITS DIRECTORS, SHAREHOLDERS, OFFICERS AND EMPLOYEES SHALL NOT BE LIABLE
FOR ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, EXEMPLARY OR CONSEQUENTIAL DAMAGES
(INCLUDING DAMAGES FOR LOSS OF BUSINESS, LOSS OF PROFITS, LITIGATION OR THE LIKE) ARISING
OUT OF (A) THE USE OF THE SITES, (B) THE CONTENT ON THE SITES, (C) WEB SITES, AND THE
CONTENT THEREIN, LINKED TO THE SITES, (D) THE GOODS OR SERVICES PROVIDED BY ANY THIRD
PARTY THROUGH THE SITES, (E) ANY DECISION MADE OR ACTION TAKEN BY USERS IN RELIANCE
UPON THE INFORMATION WITHIN, OR CONTENT OF, THE SITES, AND/OR (F) THE LIABILITY TO USE
THE SITES AND CONTENT CONTAINED THEREIN (INCLUDING, BUT NOT LIMITED TO, LOSS OF
PROFITS, GOODWILL OR SAVINGS, DOWNTIME, DAMAGE TO OR REPLACEMENT OF PROGRAMS AND
DATA), WHETHER BASE IN CONTRACT OR TORT (INCLUDING NEGLIGENCE), PRODUCT LIABILITY OR
OTHERWISE EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. Ticketday CANNOT AND
DOES NOT GUARANTEE CONTINUOUS, UNINTERRUPTED OR SECURE ACCESS TO THE SITES, SOME
STATES DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES OR THE LIMITATION OR
EXCLUSION OF LIABILITY FOR INCIDENTAL AND CONSEQUENTIAL DAMAGES, SO THE ABOVE
EXCLUSION MAY NOT APPLY TO USERS. IF, NOTWITHSTANDING THE FOREGOING, Tickethub.ng
SHOULD BE FOUND LIABLE FOR ANY LOSS OR DAMAGE WHICH ARISES OUT OF OR IS IN ANY WAY
CONNECTED WITH ANY OF THE ABOVE DESCRIBED FUNCTIONS OR USES OF THIS SITES OR ITS
CONTENT OR CONTENT PROVIDED BY Ticketday, Ticketday'S LIABILITY SHALL IN NO EVENT EXCEED,
IN THE AGGREGATE, THE FEES CHARGED BY THE SITES, IF ANY, WITH RESPECT TO WHICH LIABILITY IS
FOUND. IN ITS SOLE DISCRETION, IN ADDITION TO ANY OTHER RIGHTS OR REMEDIES AVAILABLE TO
Ticketday AND WITHOUT ANY LIABILITY WHATSOEVER, Ticketday AT ANY TIME AND WITHOUT
NOTICE MAY TERMINATE OR RESTRICT ACCESS TO A USER TO ANY COMPONENT OF THIS SITES. THE
FOREGOING TERMS OF USE SHALL APPLY TO BOTH CONTRACT AND NEGLIGENCE CLAIMS
            </Text>



            </View>
            
            <View style={{width: width /2, alignSelf: 'center', marginTop: 30}}>
                
            <Text style={{fontSize: 18, fontWeight: 'bold'}}>10. Indemnification</Text>
            <Text style={{textAlign: 'justify', marginTop: 10}}>
            Users are entirely responsible for maintaining the confidentiality of their password and account.
Furthermore, Users are entirely responsible for any and all activities which occur under their
account. Users agree to indemnify, defend and hold harmless Ticketday, its parents, affiliates and
subsidiary companies, shareholders, officers, directors, employees, consultants and agents from any
and all third party claims, liability, damages and/or costs (including, but not limited to, attorneys'
fees) arising from their use of the Services, the violation by Users of the Terms of Service or the
infringement by Users of any intellectual property or other right of any person or entity. Users agree
to immediately notify Ticketday of any unauthorized use of their account or any other breach of
security known to them. Ticketday reserves the right to use any Customer's site as a Demo site. The
Customer can request to be removed as a demo by contacting Ticketday
            </Text>



            </View>
            
            <View style={{width: width /2, alignSelf: 'center', marginTop: 30}}>
                
            <Text style={{fontSize: 18, fontWeight: 'bold'}}>11. No License; Intellectual Property of Ticketday and Others.</Text>
            <Text style={{textAlign: 'justify', marginTop: 10}}>
            Except as expressly provided, nothing herein or within any of the Services shall be construed as
conferring any license under any of Ticketday's or any third party's intellectual property rights,
whether by implication, waiver, or otherwise. Without limiting the generality of the foregoing, Users
acknowledge and agree that certain content available through and used to operate the Sites and
Services is protected by copyright, trademark, patent, or other proprietary rights of Ticketday, its
affiliates, licensors, and/or service providers. Users agree not to adapt, translate, modify, decompile,
disassemble, or reverse engineer any software, applications or programs used in connection with the
Services or the Sites. Further, Users agree not to modify, alter, or deface any of the trademarks,
service marks, or other intellectual property made available through the Sites nor use any of the
foregoing for any purpose other than the purpose for which such intellectual property is made
available to users through the Sites. Copying any content on the Sites is strictly prohibited.
Notwithstanding the foregoing, Customers hereby certify that they have the right to upload,
broadcast and distribute any content and pictures which will be posted on the Customers' website
(the "Website content") and that that such Website content does not violate this Terms of Use or
any third parties' proprietary right. Customers also hereby grants to Ticketday a perpetual nonexclusive royalty-free license to make copies, distribute and sell the Website content to Users which
may have an interest in the Customer's website.
            </Text>



            </View> 

            <View style={{width: width /2, alignSelf: 'center', marginTop: 30}}>
                
                <Text style={{fontSize: 18, fontWeight: 'bold'}}>12. Trademarks & Copyright</Text>
                <Text style={{textAlign: 'justify', marginTop: 10}}>
                Ticketday is a trademark of Ticketday. All other product, brand and company names and logos used
on the Sites are the trademarks or registered trademarks of their respective owners. Any use of any
of the marks appearing on the Sites without the prior written consent of Ticketday or the owner of
the mark, as appropriate, is strictly prohibited. All rights reserved. Any copying, republication or
redistribution of such content, including by caching, framing or similar means, is expressly prohibited
without the prior written consent of the copyright owner
                </Text>
    
    
    
            </View> 

            <View style={{width: width /2, alignSelf: 'center', marginTop: 30}}>
                
                <Text style={{fontSize: 18, fontWeight: 'bold'}}>13. Relationship</Text>
                <Text style={{textAlign: 'justify', marginTop: 10}}>
                The relationship between Ticketday and the Users will be that of independent contractors, and
neither of us nor any of our respective officers, agents or employees will be held or construed to be
partners, joint ventures, fiduciaries, employees or agents of the other
                </Text>
    
    
    
            </View>
            
            <View style={{width: width /2, alignSelf: 'center', marginTop: 30}}>
                
                <Text style={{fontSize: 18, fontWeight: 'bold'}}>14. Assignment</Text>
                <Text style={{textAlign: 'justify', marginTop: 10}}>
                Users may not assign, convey, subcontract or delegate their rights, duties or obligations hereunder.

                </Text>
    
    
    
            </View> 

            <View style={{width: width /2, alignSelf: 'center', marginTop: 30}}>
                
                <Text style={{fontSize: 18, fontWeight: 'bold'}}>15. Modification</Text>
                <Text style={{textAlign: 'justify', marginTop: 10}}>
                Ticketday may at any time modify these terms and conditions and Users' continued use of the Sites
will be conditioned upon the terms and conditions in force at the time of use by Users.
                </Text>
    
    
    
            </View> 

            <View style={{width: width /2, alignSelf: 'center', marginTop: 30}}>
                
                <Text style={{fontSize: 18, fontWeight: 'bold'}}>16. Termination</Text>
                <Text style={{textAlign: 'justify', marginTop: 10}}>
                Ticketday may terminate access by Users to the Sites and/or the provision of any of the services
offered by Ticketday at any time for any reason, including any improper use of the Sites or Users'
failure to comply with this Terms of Use. Such termination shall not affect any right to relief which
Ticketday may be entitled to, at law or in equity. Upon termination of this Terms of Use, all rights
granted to Users will terminate and revert to Ticketday.
                </Text>
    
    
    
            </View> 


            <View style={{width: width /2, alignSelf: 'center', marginTop: 30}}>
                
                <Text style={{fontSize: 18, fontWeight: 'bold'}}>17. Additional Terms</Text>
                <Text style={{textAlign: 'justify', marginTop: 10}}>
                Additional terms and conditions may apply to reservations, purchases of goods and services and
other uses of portions of the Sites, and Users agree to abide by such other terms and conditions.
                </Text>
    
    
    
            </View> 

            <View style={{width: width /2, alignSelf: 'center', marginTop: 30}}>
                
                <Text style={{fontSize: 18, fontWeight: 'bold'}}>18. Entire Agreement</Text>
                <Text style={{textAlign: 'justify', marginTop: 10}}>
                This Term of Use constitutes the entire agreement between each User and my event. Additionally,
this Terms of Use shall be deemed severable. In the event that any provision is determined to be
unenforceable or invalid, such provision shall nonetheless be enforced to the fullest extent
permitted by applicable law, and such determination shall not affect the validity and enforceability
of any other remaining provisions. The heading used in this Terms of Use are included for
convenience only. Users acknowledge that they have read this Terms of Use, understand it, and
agree to be bound by its terms and conditions. Users further agree that it is the complete and
exclusive statement of the agreement between Users and my event which supersedes any proposed
or prior agreement, oral or written, and any other communications between Users and my event
relating to their use of the Sites.
                </Text>
    
    
    
            </View> 

            <View style={{width: width /2, alignSelf: 'center', marginTop: 30}}>
                
                <Text style={{fontSize: 18, fontWeight: 'bold'}}>19. Governing Laws</Text>
                <Text style={{textAlign: 'justify', marginTop: 10}}>
                This Term of Use and its performance shall be governed by the laws currently in the Republic of
South Africa.
                </Text>
    
    
    
            </View> 

            <View style={{width: width /2, alignSelf: 'center', marginTop: 30}}>
                
                <Text style={{fontSize: 18, fontWeight: 'bold'}}>20. Refunds</Text>
                <Text style={{textAlign: 'justify', marginTop: 10}}>
                In a situation where an event is cancelled or the event is postponed, The refunds will be initiated by
the event organizer. Ticketday It’s a ticketing agent. Third-party event organizers create and manage
their events on ticketday, they are responsible for issuing refunds. If you as a customer you want a
refund for your tickets, send a request to the event organizer.
Contact us in-case you do not have details of your organizer .on support@ticketday.co.za. We will
respond to you within 2 working days.
                </Text>
    
    
    
            </View> 


        </Animated.View>

        </ScrollView>

    </View>
  )
}

export default TsnCs