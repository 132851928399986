import { View, Text, Dimensions, TouchableOpacity, Animated, Image } from 'react-native'
import React, {useState, useRef, useEffect} from 'react'
const {width, height} = Dimensions.get('screen')
import { useNavigation } from '@react-navigation/native';
import { Feather, FontAwesome, Ionicons, AntDesign, MaterialIcons, Foundation } from '@expo/vector-icons'; 
import { useStateContext } from '../src/contexts/ContextProvider';
import { Auth, API, graphqlOperation } from 'aws-amplify';
import LoginFlow from './LoginFlow';


const Header = () => {

    const navigation = useNavigation();
    const drawer = useRef(new Animated.Value(width)).current;

    const {counting, setCounting, authed, setAuthed, setAuthedUser, authedUser} = useStateContext();

    const showDrawer  = ()=> {

        Animated.parallel([
            Animated.spring(drawer, {
                toValue: 0,
                useNativeDriver: true
            })
        ]).start()

    }

    const closeDrawer  = ()=> {

        Animated.parallel([
            Animated.spring(drawer, {
                toValue: width,
                useNativeDriver: true
            })
        ]).start()

    }

    const signout = async()=> {
        try {
             await Auth.signOut().then((e)=> {
                setAuthed(false),
                closeDrawer(),
                navigation.navigate('Home')
             })   
        } catch (error) {
          console.error(error)  
        }
    }

    // if(!authed){
    //     return (
    //       <View style={{flex: 1, backgroundColor: '#fcfcfc'}}>
    //         <View style={{padding: 20}}>
    //           <TouchableOpacity onPress={()=> navigation.goBack()}>
    //             <Feather size={30} name='arrow-left' />
    //           </TouchableOpacity>
    //         </View>
    //         <LoginFlow/>
    //       </View>
    //     )
        
         
    //   }


  return (
    <View style={{height: 60, backgroundColor: '#fcfcfc', width: width}}>

        <Animated.View style={{height: 60, width: width, backgroundColor: '#fcfcfc', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-end', paddingHorizontal: 15, paddingBottom: 15}}>
            
                

            <TouchableOpacity onPress={()=> navigation.navigate('Home')} style={{height: '100%', alignItems: 'flex-end', justifyContent: 'flex-end'}}>
                <Image  source={require('../assets/dar.png')} style={{resizeMode: 'contain', width: 100, height: 45}}  />
            </TouchableOpacity>
            

            <View style={{flexDirection: 'row'}}>
                <TouchableOpacity style={{marginRight: 25}} onPress={()=> navigation.navigate('Search')}>
                    <Feather name='search' size={20}  />
                </TouchableOpacity>

                <TouchableOpacity onPress={()=> showDrawer()}>
                <Feather name='menu' size={20}  />
            </TouchableOpacity>

            </View>
            
            
        </Animated.View>

        <Animated.View style={{height: height, width: width, position: 'absolute', backgroundColor: '#1e1f24', transform: [{translateX: drawer}] }}>
            
            {!authed && 
                <View style={{height: height, width: width, backgroundColor: '#fcfcfc', position: 'absolute', zIndex: 15000}}>
                <View style={{padding: 20}}>
              <TouchableOpacity onPress={()=> closeDrawer()}>
                    <Feather size={30} name='arrow-left' />
                  </TouchableOpacity>
                </View>
                <LoginFlow/>
              </View>
            }




            <Animated.View style={{padding: 15, flexDirection: 'row', alignItems: 'center'}}>
                <TouchableOpacity onPress={()=> closeDrawer()}>
                    <Feather name='minus-square' size={20} color={'white'} />
                </TouchableOpacity>

                <View style={{marginLeft: 15}}>
                    <Text style={{color: '#fcfcfc', fontWeight: 'bold'}}>CLOSE</Text>
                </View>
            </Animated.View>


            <View style={{paddingVertical: 30, width: width - 60, backgroundColor: '#343745', borderRadius: 20, alignSelf: 'center', marginTop: 30, alignItems: 'center', justifyContent: 'center'}}>
                {authed ? 
                    <View style={{alignItems: 'center'}}>

                            <View style={{width: '100%', alignItems: 'center', marginBottom: 15}}>
                            <Text style={{fontSize: 12, color: '#fcfcfc'}}>{authedUser.firstName}</Text>
                                <Text style={{fontSize: 12, color: '#fcfcfc'}}>{authedUser.email}</Text>
                            </View>

                        <TouchableOpacity onPress={()=> signout()} style={{height: 35, width: 90, alignItems: 'center', justifyContent: 'center', backgroundColor: 'green', borderRadius: 5}}>
                            
                            <Text style={{fontSize: 10, color: '#fcfcfc', fontWeight: 'bold'}}>Signout</Text>
                        </TouchableOpacity>    
                    </View>
                    
                    : 

                    <View>
                        <TouchableOpacity onPress={()=> navigation.navigate('LoginFlow')} style={{height: 45, paddingHorizontal: 10, alignItems: 'center', justifyContent: 'center', backgroundColor: 'green', borderRadius: 5}}>
                            <Text style={{fontSize: 12, fontWeight: 'bold', color: '#fcfcfc'}}>Sign In / Register</Text>
                        </TouchableOpacity>
                    </View>
                }


                



            </View>
            
            <Animated.View style={{padding: 60}}>

            <TouchableOpacity onPress={()=> {navigation.navigate('Home'), closeDrawer()}} style={{flexDirection: 'row'}}>
                
            <View style={{width: 60}}>
                <Feather name='home' size={20} color={'#fcfcfc'} />
            </View>
                
                <Text style={{fontWeight: 'bold', fontSize: 16, color: '#fcfcfc', marginLeft: 25}}>Home</Text>
            </TouchableOpacity>

            
            <TouchableOpacity onPress={()=> {navigation.navigate('Cart'), closeDrawer()}} style={{flexDirection: 'row', marginTop: 35}}>
                <View style={{width: 60}}>
                    <Feather name= 'shopping-cart' size={20} color={'#fcfcfc'} />
                </View>
                <Text style={{fontWeight: 'bold', fontSize: 16, color: '#fcfcfc', marginLeft: 25}}>Cart</Text>
            </TouchableOpacity>

            <TouchableOpacity onPress={()=> {navigation.navigate('MyTickets'), closeDrawer()}} style={{flexDirection: 'row', marginTop: 35}}>
                <View style={{width: 60}}>
                    <Foundation name= 'ticket' size={20} color={'#fcfcfc'} />

                </View>
                <Text style={{fontWeight: 'bold', fontSize: 16, color: '#fcfcfc', marginLeft: 25}}>My Tickets</Text>
            </TouchableOpacity>

            <TouchableOpacity onPress={()=> {navigation.navigate('ChatSupport'), closeDrawer()}} style={{flexDirection: 'row', marginTop: 35}}>
                <View style={{width: 60}}>
                    <Feather name= 'message-square' size={20} color={'#fcfcfc'} />

                </View>
                <Text style={{fontWeight: 'bold', fontSize: 16, color: '#fcfcfc', marginLeft: 25}}>Chat Support</Text>
            </TouchableOpacity>


            </Animated.View>
            
            

        </Animated.View>


    




    </View>
  )
}

export default Header