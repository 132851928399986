import { View, Text, Animated, TouchableOpacity, ImageBackground, Dimensions, ScrollView } from 'react-native'
import React from 'react'
import Header from '../Components/Header';
import { useNavigation, useRoute } from '@react-navigation/native';
import { Feather, FontAwesome, Ionicons, AntDesign, MaterialIcons, MaterialCommunityIcons, Entypo } from '@expo/vector-icons'; 

const {width, height} = Dimensions.get('screen')

const ListEvent = () => {

    const navigation = useNavigation();

    const ourOrange = '#FF4F00';
    const headImg = 'https://images.unsplash.com/photo-1674574124792-3be232f1957f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80'

  return (
    <View style={{flex: 1, backgroundColor: '#fcfcfc'}}>
        <Animated.View style={{flexDirection: 'row', height: 100, width: width}}>
        <ImageBackground source={{uri: headImg}} style={{height: '100%', width: width}}>
        <Animated.View style={{position: 'absolute', top: 0, left: 0, zIndex: 9000}}>
            <TouchableOpacity onPress={()=> navigation.goBack()} style={{height: 60, width: 60, backgroundColor: ourOrange, alignItems: 'center', justifyContent: 'center'}}>
                <Feather name='arrow-left' size={30} color={'#fcfcfc'}  />
            </TouchableOpacity>
        </Animated.View>
        <View style={{height: '100%', width: '100%', backgroundColor: 'black', opacity: 0.4}}>

        </View>

        </ImageBackground>

    

        </Animated.View>

        <ScrollView>
            
        <Animated.View style={{padding: 30}}>
            <Text style={{alignSelf: 'center', fontWeight: 'bold', fontSize: 30}}>List Your Event</Text>

            <View style={{width: width /2, alignSelf: 'center', marginTop: 30}}>

                <View>
                    
                
                <Text style={{textAlign: 'justify', marginTop: 10, marginLeft: 0}}>
                Your cheapest and reliable ticketing partner.
                Pricing-2.9% commission ex VAT and R 9.99 booking fee paid by the customer. 1voucher fees: on application. </Text>
            
                </View>


                <View style={{marginTop: 30}}>
                    <View style={{flexDirection: 'row', alignItems: 'center'}}>
                        <View style={{height: 8, width: 8, borderRadius: 10, backgroundColor: 'black', marginRight: 30}} />
                        <Text style={{textAlign: 'justify',  marginLeft: 0}}>All tickets are safe, reliable place to buy tickets from a primary agent</Text>
                    </View>

                    <View style={{flexDirection: 'row', alignItems: 'center', marginTop: 20}}>
                        <View style={{height: 8, width: 8, borderRadius: 10, backgroundColor: 'black', marginRight: 30}} />
                        <Text style={{textAlign: 'justify',  marginLeft: 0}}>No Yap-Yap we just pay your money to your bank account within 2 days after your event date.</Text>
                    </View>

                    <View style={{flexDirection: 'row', alignItems: 'center', marginTop: 20}}>
                        <View style={{height: 8, width: 8, borderRadius: 10, backgroundColor: 'black', marginRight: 30}} />
                        <Text style={{textAlign: 'justify',  marginLeft: 0}}>Flexible and fair pricing for your event</Text>
                    </View>

                    <View style={{flexDirection: 'row', alignItems: 'center', marginTop: 20}}>
                        <View style={{height: 8, width: 8, borderRadius: 10, backgroundColor: 'black', marginRight: 30}} />
                        <Text style={{textAlign: 'justify',  marginLeft: 0}}>Card and 1 voucher payment methods</Text>
                    </View>

                    <View style={{flexDirection: 'row', alignItems: 'center', marginTop: 20}}>
                        <View style={{height: 8, width: 8, borderRadius: 10, backgroundColor: 'black', marginRight: 30}} />
                        <Text style={{textAlign: 'justify',  marginLeft: 0}}>Security you can rely on. Payment processing uses the very latest PCI DSS card-
security standards. That’s why you can trust us. All transactions are transparent and
straight forward.</Text>
                    </View>

                    <View style={{flexDirection: 'row', alignItems: 'center', marginTop: 20}}>
                        <View style={{height: 8, width: 8, borderRadius: 10, backgroundColor: 'black', marginRight: 30}} />
                        <Text style={{textAlign: 'justify',  marginLeft: 0}}>Our site is exceptionally user-friendly and extremely easy to use by ticket buyers.</Text>
                    </View>

                    <View style={{flexDirection: 'row', alignItems: 'center', marginTop: 20}}>
                        <View style={{height: 8, width: 8, borderRadius: 10, backgroundColor: 'black', marginRight: 30}} />
                        <Text style={{textAlign: 'justify',  marginLeft: 0}}>Track sales and data anytime from us.</Text>
                    </View>

                    <View style={{flexDirection: 'row', alignItems: 'center', marginTop: 20}}>
                        <View style={{height: 8, width: 8, borderRadius: 10, backgroundColor: 'black', marginRight: 30}} />
                        <Text style={{textAlign: 'justify',  marginLeft: 0}}>Whatsapp chat anytime for any enquiries or support.</Text>
                    </View>

                    <View style={{flexDirection: 'row', alignItems: 'center', marginTop: 20}}>
                        <View style={{height: 8, width: 8, borderRadius: 10, backgroundColor: 'black', marginRight: 30}} />
                        <Text style={{textAlign: 'justify',  marginLeft: 0}}>Use our scanner app to scan the ticket barcode effortlessly for quick entry.</Text>
                    </View>

                    <View style={{flexDirection: 'row', alignItems: 'center', marginTop: 20}}>
                        <View style={{height: 8, width: 8, borderRadius: 10, backgroundColor: 'black', marginRight: 30}} />
                        <Text style={{textAlign: 'justify',  marginLeft: 0}}>Set up your event within 7 minutes on the organiser dashboard or let us load up the
event for you.</Text>
                    </View>
                
                
                </View>
          

            </View>

        
         
        

        </Animated.View>

        </ScrollView>

    </View>
  )
}

export default ListEvent