import { StyleSheet, Text, View, TouchableOpacity, Dimensions, Image, ActivityIndicator, TextInput, Animated, FlatList } from 'react-native'
import React, {useState, useEffect} from 'react';
import { Feather } from '@expo/vector-icons'; 
import Header from '../Components/Header';
import { Auth, API, graphqlOperation } from 'aws-amplify';
import * as queries from '../src/graphql/queries';
import * as mutations from '../src/graphql/mutations';
const {width, height} = Dimensions.get('screen');
import { useStateContext } from '../src/contexts/ContextProvider';
import EmptyComp from '../Components/EmptyComp';
import LoginFlow from '../Components/LoginFlow';
import { useNavigation, useRoute } from '@react-navigation/native';


const ChatScreen = () => {
   const {counting, setCounting, authed, setAuthed} = useStateContext();

   const navigation = useNavigation()
  const [ticketsall, setTicketsAll] = useState([])
  const [spinning, setSpinning] = useState(false)
  const [message, setMessage] = useState('');

  

  const route = useRoute().params

  const fetchingTickets = async()=> {

  
    if(authed){
      const userIdd = await Auth.currentAuthenticatedUser({bypassCache: true})

    

      const todoData = await API.graphql({query: queries.listChats, variables:  
          
           {
            filter : { and: [ 
                {ChatRoom: {eq: route?.roomId}}
            ]}
        }


      })
          const todos = todoData.data.listChats.items
          setTicketsAll(todos)
    }


    
}

useEffect(()=> {
    fetchingTickets();
}, [ticketsall, authed])


const ourOrange = '#FF4F00';

ticketsall.sort(function(a, b) {

  const newerDate = String(a.createdAt).replace(/\D/g, "")
  const newerB = String(b.createdAt).replace(/\D/g, "")

  return parseFloat(newerB) - parseFloat(newerDate);
  });


  if(authed === false){
    return (
      <View style={{flex: 1, backgroundColor: '#fcfcfc'}}>
        <View style={{padding: 20}}>
          <TouchableOpacity onPress={()=> navigation.goBack()}>
            <Feather size={30} name='arrow-left' />
          </TouchableOpacity>
        </View>
        <LoginFlow/>
      </View>
    )
    
     
  }


    const renderTickets = ({item, index})=> {

      if(item?.userId){
        return(
          <View style={{width: width, paddingVertical: 0, paddingHorizontal: 15, marginRight: 15}}>
            
          <View style={{flexDirection: 'row', alignItems: 'center', backgroundColor: '#fcfcfc', borderRadius: 15, position: 'relative', right: 25, borderTopRightRadius: 0}}>
            
            

            <View style={{marginLeft: 10, padding: 15}}>
  
              <View style={{}}>
                <Text style={{fontSize: 12}}>{item.message}</Text>
              </View>
              <Text style={{fontSize: 9, color: 'gray'}}>{String(item.createdAt).slice(0,10)}</Text>
            </View>
           
          
          </View>
          
          </View>
        )
      } else {
        return(
          <View style={{width: width - 30, paddingVertical: 15, paddingHorizontal: 0, marginRight: 0, alignSelf: 'center'}}>
            
          <View style={{flexDirection: 'row', alignItems: 'center', backgroundColor: '#f0fcf3', borderRadius: 15, position: 'relative', right: 0, borderTopLeftRadius: 0}}>
            
            

            <View style={{marginLeft: 10, padding: 15}}>
  
              <View style={{}}>
                <Text style={{fontSize: 12}}>{item.message}</Text>
  
              </View>
              <Text style={{fontSize: 9, color: 'gray'}}>{String(item.createdAt).slice(0,10)}</Text>
            </View>
           
          
          </View>
          
          </View>
        )
      }


     
    }

    const itemSeperator = () => {
      return(
        <View style={{height: 10}} />
      )
    }


    const newMessage = async() => {

      if(!message){
        return
      }

      setSpinning(true)

      const userD = await Auth.currentAuthenticatedUser()

      const newChatInput = {
        userId: userD.attributes.sub,
        ChatRoom: route?.roomId,
        message: message,
        read: false,
      }

        try {
          
          await API.graphql(graphqlOperation(mutations.createChats, { input: newChatInput})).then((e)=> {
                setMessage(''),
                setSpinning(false)        
          })


        } catch (error) {
          console.error(error),
          setSpinning(false)
        }


    }



  return (
    <View style={{flex: 1, backgroundColor: '#e3eefa'}}>
               
          {authed === true ? 
          <View style={{padding: 0}}>

            <View style={{height: 60, width: width, flexDirection: 'row', backgroundColor: '#fcfcfc', marginBottom: 15, alignItems: 'center', paddingLeft: 15}}>
              <TouchableOpacity onPress={()=> navigation.goBack()}>
                <Feather name='arrow-left'  size={15}/>
              </TouchableOpacity>
              <Text style={{fontSize: 12, marginLeft: 15, fontWeight: 'bold', marginBottom: 0}}>{String(route?.subject).slice(0,20)}</Text>
            </View>

            <FlatList
              showsVerticalScrollIndicator={false}
              data={ticketsall}
              renderItem={renderTickets}
              ItemSeparatorComponent={itemSeperator}
              style={{height: height / 1.5 + 20, padding: 15}}
            />

            <View style={{height: 60, width: width, position: 'absolute', bottom: 0, backgroundColor: '#fcfcfc', flexDirection: 'row', paddingLeft: 15, alignItems: 'center'}}>
              <TextInput placeholder='type your message' value={message} onChangeText={setMessage} style={{fontSize: 12, fontStyle: 'italic'}} multiline={true} />

              <View style={{height: 40, width: 40, position: 'absolute', right: 15, top: 15}}>
               
                {spinning ? 
                  
                  <View style={{width: 30, height: 30, backgroundColor: 'red', borderRadius: 30, alignItems: 'center', justifyContent: 'center'}}>
                    <ActivityIndicator size={'small'} color={'white'} />  
                  </View>
                  
                  
                  
                  :
                  <TouchableOpacity onPress={()=> newMessage()} style={{width: 30, height: 30, backgroundColor: 'red', borderRadius: 30, alignItems: 'center', justifyContent: 'center'}}>
                  <Feather name='send' size={15} color={'white'} />
                  </TouchableOpacity>
              }

                
              </View>

            </View>




          </View>
          :



          <View style={{flex: 1, backgroundColor: '#fcfcfc', alignItems: 'center', justifyContent: 'center'}}>
          <Image source={require('../assets/iconsmsall.png')} style={{height: 30, width: 80, resizeMode: 'contain'}} />
          <Text style={{marginTop: 15}}>Loading your chats...</Text>

          <ActivityIndicator size={'small'} color={'purple'} style={{marginTop: 10}} />
        
          </View>
          }
    </View>
  )
}

export default ChatScreen

const styles = StyleSheet.create({})