import { StyleSheet, Text, View, TouchableOpacity, Dimensions, Image, ActivityIndicator, TextInput, Animated, FlatList } from 'react-native'
import React, {useState, useEffect} from 'react';
import { Feather } from '@expo/vector-icons'; 
import Header from '../Components/Header';
import { Auth, API, graphqlOperation } from 'aws-amplify';
import * as queries from '../src/graphql/queries';
import * as mutations from '../src/graphql/mutations';
const {width, height} = Dimensions.get('screen');
import { useStateContext } from '../src/contexts/ContextProvider';
import EmptyComp from '../Components/EmptyComp';
import LoginFlow from '../Components/LoginFlow';
import { useNavigation } from '@react-navigation/native';
import { Picker } from '@react-native-picker/picker';



const ChatSupport = () => {
   const {counting, setCounting, authed, setAuthed} = useStateContext();

   const navigation = useNavigation()
  const [ticketsall, setTicketsAll] = useState([])
  const [subject, setSubject] = useState('General')
  const [message, setMessage] = useState('');
  const [spinning, setSpinning] = useState(false)

  const fetchingTickets = async() => {

  
    if(authed){
      const userIdd = await Auth.currentAuthenticatedUser({bypassCache: true})

    

      const todoData = await API.graphql({query: queries.listChatRooms, variables:  
          {filter :   { userId: 
                  {eq: 
                      (userIdd?.attributes.sub)
                  },
                  }
           }
      })
          const todos = todoData.data.listChatRooms.items
          setTicketsAll(todos)
    }


    
}

useEffect(()=> {
    fetchingTickets();
}, [ticketsall, authed])


const ourOrange = '#FF4F00';


  if(authed === false){
    return (
      <View style={{flex: 1, backgroundColor: '#fcfcfc'}}>
        <View style={{padding: 20}}>
          <TouchableOpacity onPress={()=> navigation.goBack()}>
            <Feather size={30} name='arrow-left' />
          </TouchableOpacity>
        </View>
        <LoginFlow/>
      </View>
    )
         
  }

    const renderTickets = ({item, index})=> {
      return(
        <TouchableOpacity onPress={()=> navigation.navigate('ChatScreen', {subject: item?.subject, roomId: item?.id})} style={{height: 80, width: '100%', flexDirection: 'row', alignItems: 'center', backgroundColor: '#fcfcfc', borderRadius: 10, paddingLeft: 10}}>
         
          <View style={{marginLeft: 20}}>
            <Text style={{fontSize: 12, fontWeight: 'bold'}}>{item.subject}</Text>
            <View style={{flexDirection: 'row', marginTop: 5}}>
              <Text style={{fontSize: 10, marginLeft: 0, color: 'gray'}}>{String(item.updatedAt)}</Text>             
            </View>
          </View>

          
        </TouchableOpacity>
      )
    }

    const itemSeperator = () => {
      return(
        <View style={{height: 10}} />
      )
    }


    const newMessage = async() => {

      setSpinning(true)

      if(!message){
        setSpinning(false)
        return
      }

      const userD = await Auth.currentAuthenticatedUser()

      const newChatRoomInput = {
        userId: userD.attributes.sub,
        subject: subject,
        open: false,
        userName: '',
      }

        try {
          
          await API.graphql(graphqlOperation(mutations.createChatRoom, { input: newChatRoomInput})).then((e)=> {
            const chatRoomId = e.data.createChatRoom.id

            const newChatInput = {
              userId: userD.attributes.sub,
              message: message,
              read: false,
              ChatRoom: chatRoomId
            }

            try {
              API.graphql(graphqlOperation(mutations.createChats, { input: newChatInput})).then((es)=> {
                navigation.navigate('ChatScreen', {roomId: chatRoomId, subject: subject})
                setSpinning(false)
              })
            } catch (error) {
                console.error(error),
                setSpinning(false)
            }
            
          })


        } catch (error) {
          console.error(error)
          setSpinning(false)
        }


    }

    ticketsall.sort(function(a, b) {

      const newerDate = String(a.updatedAt).replace(/\D/g, "")
      const newerB = String(b.updatedAt).replace(/\D/g, "")
  
      return parseFloat(newerB) - parseFloat(newerDate);
      });

  return (
    <View style={{flex: 1, backgroundColor: '#e3eefa'}}>
        <View style={{zIndex: 19000}}>
         <Header/>
        </View>
        
          {authed === true ? 
          <View style={{padding: 0}}>

            <View style={{height: 60, width: width, backgroundColor: '#fcfcfc', marginBottom: 15, justifyContent: 'center', paddingLeft: 15}}>
              <Text style={{fontSize: 16, fontWeight: 'bold', marginBottom: 0}}>Support Center</Text>
            </View>

            <View style={{width: width - 30, height: width /2 + 60, backgroundColor: '#fcfcfc', margin: 10, borderRadius: 10}}>

              <View style={{height: 50, width: width / 2, justifyContent: 'center', paddingHorizontal: 0, backgroundColor: '#edf1f2', borderRadius: 10, marginBottom: 15, marginTop: 10, alignSelf: 'center'}}>
                
              <Picker
                        style={{height: '100%', width: '100%', borderRadius: 10, backgroundColor: '#edf1f2'}}
                        selectedValue={subject}
                        onValueChange={(itemValue, itemIndex) =>
                            {setSubject(itemValue)}
                        }>
                          <Picker.Item label="General" value="General" />
                        <Picker.Item label="Challenge buying ticket" value="Challenge Buying Ticket" />
                        <Picker.Item label="QR Code doesnt work" value="QR Code doesnt work" />
                        <Picker.Item label="Fraud" value="Fraud" />
                        <Picker.Item label="I can't find my ticket" value="I can't find my ticket" />
                        <Picker.Item label="Billing Issue" value="Billing Issue" />
                        <Picker.Item label="Other" value="Other" />
                </Picker>

              </View>

              <View style={{height: 150, width: width / 2, justifyContent: 'center', paddingHorizontal: 15, backgroundColor: '#edf1f2', borderRadius: 10, marginBottom: 15, marginTop: 10, alignSelf: 'center'}}>
                <TextInput placeholder='type your message' multiline={true} style={{height: '80%'}} value={message} onChangeText={setMessage} />
              </View>

              <View>

                    {spinning ? 
                     

                    <TouchableOpacity style={{height: 45, width: width / 2, alignSelf: 'center', backgroundColor: 'green', alignItems: 'center', justifyContent: 'center', borderRadius: 10}}>
                      <ActivityIndicator size={'small'} color={'white'} />  
                    </TouchableOpacity>

                    : 
                    
                    <TouchableOpacity onPress={()=> newMessage()} style={{height: 45, width: width / 2, alignSelf: 'center', backgroundColor: 'green', alignItems: 'center', justifyContent: 'center', borderRadius: 10}}>
                    <Text style={{color: '#fcfcfc', fontWeight: 'bold'}}>Send Message</Text>
                  </TouchableOpacity>
  
                  }

              </View>

            </View>

            <FlatList
              showsVerticalScrollIndicator={false}
              data={ticketsall}
              renderItem={renderTickets}
              ItemSeparatorComponent={itemSeperator}
              style={{height: height / 1.5 + 20, padding: 15}}
            />
          </View>
          :



          <View style={{flex: 1, backgroundColor: '#fcfcfc', alignItems: 'center', justifyContent: 'center'}}>
          <Image source={require('../assets/iconsmsall.png')} style={{height: 30, width: 80, resizeMode: 'contain'}} />
          <Text style={{marginTop: 15}}>Loading your chats...</Text>

          <ActivityIndicator size={'small'} color={'purple'} style={{marginTop: 10}} />
        
          </View>
          }
    </View>
  )
}

export default ChatSupport

const styles = StyleSheet.create({})