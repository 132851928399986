import { View, Text, Dimensions, ImageBackground, Image, TouchableOpacity, Animated, ScrollView, ActivityIndicator } from 'react-native'
import React, {useEffect, useState} from 'react';
import { useNavigation, useRoute } from '@react-navigation/native';
import { Feather, FontAwesome, Ionicons, AntDesign, MaterialIcons, MaterialCommunityIcons, Entypo } from '@expo/vector-icons'; 
import { API, Auth, graphqlOperation } from 'aws-amplify';
import * as queries from '../src/graphql/queries';
import * as mutations from '../src/graphql/mutations';
import LoginFlow from '../Components/LoginFlow';
import { useStateContext } from '../src/contexts/ContextProvider';
import { getEvent } from '../src/graphql/queries';

const {width, height} = Dimensions.get('screen')


const EventTicketsLink = () => {

    const route = useRoute().params
    const routeInfo = route.item
    const routeLink = route.id

    const navigation = useNavigation();

    const ourOrange = '#FF4F00';

    const [spinning, setSpinning] = useState(false);
    const {counting, setCounting, authed, setAuthed} = useStateContext();


    const [events, setEvents] = useState([])
    const [showAddCart, setShowAddCart] = useState(false)
    const [quantity, setQuantity] = useState(1);
    const [selectedTicket, setSelectedTicket] = useState({
        title: '',
        amount: 0,
        image: '',
        seatId: '',
        eventId: '',
        campaignId: '',
    })

    const [eventInfo, setEventInfo] = useState()
    const imageplace = 'https://www.namepros.com/attachments/empty-png.89209/'


    const getUserInfo = async()=>{

        try{
            await API.graphql(graphqlOperation(getEvent, { id: routeLink})).then((ef)=> {
            const userd = ef.data.getEvent
      
            setEventInfo(userd)

            // gettingUserData(userd.campaignId)
      
          }).catch((ee)=> {
            console.error(ee)
          })
      
      } catch (e){
        console.error(e)
        
      }

    }





    const gettingUserData = async()=> {


        const allTodos = await API.graphql({query: queries.listTickets, variables: {
            filter: {
                eventId: {eq: routeLink}
            }
        }})

        const eventsData = allTodos.data.listTickets.items
        setEvents(eventsData)
      
    }

    useEffect(()=> {
        getUserInfo()
        gettingUserData()      
    },[events])


    


    const addToCart = async()=> {

        if(authed){
        
        setSpinning(true)

        const userIdd = await Auth.currentAuthenticatedUser();

        const inputData = {
            userId: userIdd?.attributes.sub,
            image: selectedTicket.image,
            title: selectedTicket.title,
            amount: selectedTicket.amount,
            vendorId: eventInfo?.ownerId,
            qty: quantity,
            seatId: selectedTicket.seatId ,
            eventId: selectedTicket.eventId,
            campaignId: eventInfo?.campaignId,
            ownerId: eventInfo?.ownerId
            // city: city
        }

        try {
            await API.graphql({query: mutations.createCart, variables: {input: inputData}}).then((ee)=> {
                navigation.navigate('Cart'),
                setSpinning(false)
              }) 
        } catch (error) {
            console.error(error),
            setSpinning(false)
        }

        } else {
            navigation.navigate('MyTickets')
        }

    }



  return (
    <View style={{flex: 1, backgroundColor: '#fcfcfc'}}>

        <Animated.View style={{flexDirection: 'row', height: 100, width: width}}>
            <ImageBackground source={{uri: eventInfo?.image || `https://www.namepros.com/attachments/empty-png.89209/`}} style={{height: '100%', width: width}}>
                <Animated.View style={{position: 'absolute', top: 0, left: 0, zIndex: 9000}}>
                    <TouchableOpacity onPress={()=> navigation.goBack()} style={{height: 60, width: 60, backgroundColor: ourOrange, alignItems: 'center', justifyContent: 'center'}}>
                        <Feather name='arrow-left' size={30} color={'#fcfcfc'}  />
                    </TouchableOpacity>
                </Animated.View>
                <View style={{height: '100%', width: '100%', backgroundColor: 'black', opacity: 0.4}}>

                </View>

            </ImageBackground>

            

        </Animated.View>

      <Animated.View style={{height: 60, width: width,backgroundColor: '#fcfcfc', justifyContent: 'center', paddingHorizontal: 15}}>
            <Text style={{fontSize: 18, fontWeight: 'bold'}}>{eventInfo?.name}</Text>
        </Animated.View>
        <View style={{height: 10, width: width, backgroundColor: '#edf1f2'}} />

        <ScrollView showsVerticalScrollIndicator={false}>

        <Animated.View style={{padding: 15}}>
            <Text style={{fontSize: 14}}>Event Details</Text>
            <View style={{flexDirection: 'row', justifyContent: 'flex-start', marginTop: 25}}>
                <View style={{flexDirection: 'row', width: '45%'}}>
                    <Feather name='calendar' size={12} />
                    <Text style={{fontSize: 12, marginLeft: 15}}>{eventInfo?.eventDate || `--`}</Text>
                </View>

                <View style={{flexDirection: 'row'}}>
                    <Feather name='clock' size={12} />
                    <Text style={{fontSize: 12, marginLeft: 15}}>{eventInfo?.eventTime || `--`}</Text>
                </View>
            </View>

            <View style={{flexDirection: 'row', justifyContent: 'flex-start', marginTop: 15}}>
                <View style={{flexDirection: 'row', width: '45%'}}>
                    <Feather name='alert-octagon' size={12} />
                    <Text style={{fontSize: 12, marginLeft: 15}}>Cashless</Text>
                </View>

                <View style={{flexDirection: 'row'}}>
                    <Feather name='map' size={12} />
                    <Text style={{fontSize: 12, marginLeft: 15}}>{eventInfo?.address || `--`}</Text>
                </View>
            </View>

            <View style={{flexDirection: 'row', justifyContent: 'flex-start', marginTop: 15}}>
                <View style={{flexDirection: 'row', width: '45%'}}>
                    <Feather name='camera' size={12} />
                    <Text style={{fontSize: 12, marginLeft: 15}}>--</Text>
                </View>

                <View style={{flexDirection: 'row'}}>
                    <Feather name='alert-triangle' size={12} />
                    <Text style={{fontSize: 12, marginLeft: 15}}>--</Text>
                </View>
            </View>

        </Animated.View>

        <View style={{height: 10, width: width, backgroundColor: '#edf1f2'}} />

        <Animated.View style={{padding: 15}}>
            <Text style={{fontSize: 14}}>Event Tickets</Text>
            <Text style={{fontSize: 12, marginTop: 20, textAlign: 'justify'}}></Text>
            {events.map((e, i)=> {
                return(
                    <TouchableOpacity onPressIn={()=> { 
                        setSelectedTicket({
                            title: eventInfo?.name,
                            amount: e.amount,
                            image: eventInfo?.image || imageplace,
                            seatId: e.name,
                            eventId: eventInfo?.id,
                            campaignId: e.campaignId

                        }),
                    
                    setShowAddCart(true)}} onPress={()=> navigation.navigate('', {item: e})} style={{height: 80, width: width - 30, marginBottom: 10, borderRadius: 12, flexDirection: 'row', borderWidth: 4, borderColor: '#edf1f2', paddingHorizontal: 10, alignItems: 'center'}}>
                       
                        <View style={{marginLeft: 25}}>
                            <Text style={{fontSize: 12, fontWeight: 'bold'}}>{e.name}</Text>
                            <View style={{marginTop: 5, flexDirection: 'row'}}>
                                <Feather name='calendar' size={15} color={'gray'} />
                                <Text style={{fontSize: 12, marginLeft: 15}}>R{Number(e.amount).toFixed(2)}</Text>
                            </View>
                        </View>

                        <View style={{height: 30, width: 30, borderRadius: 5, alignItems: 'center', justifyContent: 'center', backgroundColor: ourOrange, position: 'absolute', bottom: 15, right: 15}}>
                            <Feather name='plus' size={15} color={'#fcfcfc'} />
                        </View>
                    </TouchableOpacity>
                )
            })}
        </Animated.View>    


        {
            showAddCart && 
            <View style={{position: 'absolute', width: width, height: height / 1.5, alignItems: 'center', justifyContent: 'center',  backgroundColor: 'rgba(0,0,0,0.8)',}}>
                <View style={{width: width - 30, borderRadius: 10, backgroundColor: '#fcfcfc', padding: 15}}>

                    <View>
                        <Text style={{fontSize: 16, fontWeight: 'bold'}}>Quantity</Text>
                    </View>

                    <View style={{flexDirection: 'row', marginTop: 10, alignSelf: 'center', marginVertical: 20}}>
                <TouchableOpacity onPress={()=> {quantity >= 2 ? setQuantity(quantity-1) : setQuantity(quantity-0)}} style={{height: 40, width: 40, borderRadius: 5, alignItems: 'center', justifyContent: 'center', backgroundColor: ourOrange}}>
                    <Text style={{fontWeight: 'bold', color: '#fcfcfc'}}>-</Text>
                </TouchableOpacity>


                <View style={{height: 30, width: 50, alignItems: 'center', justifyContent: 'center'}}>
                    <Text style={{fontWeight: 'bold', fontSize: 12}}>{quantity}</Text>
                </View>

                <TouchableOpacity onPress={()=> {setQuantity(quantity+1)}} style={{height: 40, width: 40, borderRadius: 5, alignItems: 'center', justifyContent: 'center', backgroundColor: ourOrange}}>
                    <Text style={{fontWeight: 'bold', color: '#fcfcfc'}}>+</Text>
                </TouchableOpacity>


                    </View>

                    <View style={{flexDirection: 'row', justifyContent: 'space-evenly'}}>
                        <TouchableOpacity onPress={()=> addToCart()} style={{height: 40, width: width / 3, alignItems: 'center', justifyContent: 'center', backgroundColor: ourOrange, borderRadius: 5}}>
                            {spinning && 
                                <View style={{position: 'absolute', left: 10}}>
                                    <ActivityIndicator size={'small'} color={'#fcfcfc'} />
                                </View>
                            }
                            <Text style={{fontSize: 12, color: '#fcfcfc', fontWeight: 'bold'}}>Add To Cart</Text>
                        </TouchableOpacity>

                        <TouchableOpacity onPress={()=> {setShowAddCart(false)}} style={{height: 40, width: width / 3, alignItems: 'center', justifyContent: 'center', borderWidth: 1, borderColor: ourOrange, borderRadius: 5}}>
                            <Text style={{fontSize: 12, color: ourOrange, fontWeight: 'bold'}}>Cancel</Text>
                        </TouchableOpacity>
                    </View>

                </View>
            </View>
        }


        </ScrollView>
    </View>
  )
}

export default EventTicketsLink