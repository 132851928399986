import { View, Text, Animated, TouchableOpacity, ImageBackground, Dimensions, ScrollView } from 'react-native'
import React from 'react'
import Header from '../Components/Header';
import { useNavigation, useRoute } from '@react-navigation/native';
import { Feather, FontAwesome, Ionicons, AntDesign, MaterialIcons, MaterialCommunityIcons, Entypo } from '@expo/vector-icons'; 

const {width, height} = Dimensions.get('screen')

const FAQs = () => {

    const navigation = useNavigation();

    const ourOrange = '#FF4F00';
    const headImg = 'https://images.unsplash.com/photo-1674574124792-3be232f1957f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80'

  return (
    <View style={{flex: 1, backgroundColor: '#fcfcfc'}}>
        <Animated.View style={{flexDirection: 'row', height: 100, width: width}}>
        <ImageBackground source={{uri: headImg}} style={{height: '100%', width: width}}>
        <Animated.View style={{position: 'absolute', top: 0, left: 0, zIndex: 9000}}>
            <TouchableOpacity onPress={()=> navigation.goBack()} style={{height: 60, width: 60, backgroundColor: ourOrange, alignItems: 'center', justifyContent: 'center'}}>
                <Feather name='arrow-left' size={30} color={'#fcfcfc'}  />
            </TouchableOpacity>
        </Animated.View>
        <View style={{height: '100%', width: '100%', backgroundColor: 'black', opacity: 0.4}}>

        </View>

        </ImageBackground>

    

        </Animated.View>

        <ScrollView>
            
        <Animated.View style={{padding: 30}}>
            <Text style={{alignSelf: 'center', fontWeight: 'bold', fontSize: 30}}>Frequently Asked Questions</Text>

            <View style={{width: width /2, alignSelf: 'center', marginTop: 30}}>

                <View>
                    
                
            <Text style={{fontSize: 16, fontWeight: 'bold'}}>How do I use ticketday ?</Text>
            <Text style={{textAlign: 'justify', marginTop: 10, marginLeft: 0}}>
            To purchase tickets, first find your event by browsing on our site the
            event you are looking for. Then select the tickets you want from the list of options and add them to the cart by clicking the Buy button. Please note: the tickets offered for sale on our website may be listed at above the “face value” printed on the tickets. To finish your order, follow the instructions on the checkout page to fill in the necessary information. The checkout page will list the total charge on your order including fees for service charge.</Text>
            
                </View>


                <View style={{marginTop: 30}}>
                    
                
                    <Text style={{fontSize: 16, fontWeight: 'bold'}}>How do I find my Tickets ?</Text>
                    <Text style={{textAlign: 'justify', marginTop: 10, marginLeft: 0}}>
                    Log in to ticket-day, sign in (create account) Purchase your ticket. All tickets will be under My tickets on the menu bar once you have created your account on ticketday.</Text>
                    
                </View>
          
                <View style={{marginTop: 30}}>
                
                    <Text style={{fontSize: 16, fontWeight: 'bold'}}>Is printing my ticket a must ?</Text>
                    <Text style={{textAlign: 'justify', marginTop: 10, marginLeft: 0}}>
                    No, your ticket will be a digital ticket. You will show it on your phone to be scanned at the event venue</Text>
                    
                </View>

                <View style={{marginTop: 30}}>
                
                    <Text style={{fontSize: 16, fontWeight: 'bold'}}>How can I pay for my Tickets ?</Text>
                    <Text style={{textAlign: 'justify', marginTop: 10, marginLeft: 0}}>
                    We accept all major credit and debit cards (Visa, Master-card and American Express.) we also accept 1 voucher payment option, meaning you can purchase tickets from us without a bank card on our check out. All you have is to visit any participating store partnered with 1 voucher. Purchase the voucher then use the 16 pin on our checkout to purchase your ticket. It’s that simple.</Text>
                    
                </View>

                <View style={{marginTop: 30}}>
                
                    <Text style={{fontSize: 16, fontWeight: 'bold'}}>Is it safe to use/buy on ticketday websites?</Text>
                    <Text style={{textAlign: 'justify', marginTop: 10, marginLeft: 0}}>
                    Yes it is safe. All transactions are by one of South Africa’s largest online payment gateway, peach payments. This means every payment comes with the latest PCI DSS security measures automatically in place.</Text>
                    
                </View>

                <View style={{marginTop: 30}}>
                
                    <Text style={{fontSize: 16, fontWeight: 'bold'}}>How do I get into an event?</Text>
                    <Text style={{textAlign: 'justify', marginTop: 10, marginLeft: 0}}>
                    Just show your digital ticket, it will be scanned once within 2 seconds, you will get access to the event.</Text>
                    
                </View>

                <View style={{marginTop: 30}}>
                
                    <Text style={{fontSize: 16, fontWeight: 'bold'}}>If my event is cancelled, will I get a refund ?</Text>
                    <Text style={{textAlign: 'justify', marginTop: 10, marginLeft: 0}}>
                    If you event was entirely cancelled, you are eligible to receive a refund under the refund policy stated in our Terms of Use. If your event is only partially cancelled, or if a performer, artist, or player is unable to perform, you will not receive a refund.</Text>
                    
                </View>




            </View>

        
         
        

        </Animated.View>

        </ScrollView>

    </View>
  )
}

export default FAQs