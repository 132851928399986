import { View, Text, Dimensions, ImageBackground, Image, TouchableOpacity, Animated, ScrollView } from 'react-native'
import React, {useState, useEffect} from 'react';
import { useNavigation, useRoute } from '@react-navigation/native';
import { Feather, FontAwesome, Ionicons, AntDesign, MaterialIcons, Entypo } from '@expo/vector-icons'; 
import * as queries from '../src/graphql/queries';
const {width, height} = Dimensions.get('screen')
import { API, Auth, graphqlOperation } from 'aws-amplify';
import { getEvent } from '../src/graphql/queries';
import * as Linking from 'expo-linking';

const EventDetailsLink = () => {

    const route = useRoute().params
    const routeInfo = route.item
    const routeLink = route.id

    const navigation = useNavigation();
    const [events, setEvents] = useState([])
    const [eventInfo, setEventInfo] = useState()

    const ourOrange = '#FF4F00';

    const imageplace = 'https://www.namepros.com/attachments/empty-png.89209/'

    const getUserInfo = async()=>{

        try{
            await API.graphql(graphqlOperation(getEvent, { id: routeLink})).then((ef)=> {
            const userd = ef.data.getEvent
      
            setEventInfo(userd)

            gettingUserData(userd.campaignId)
      
          }).catch((ee)=> {
            console.error(ee)
          })
      
      } catch (e){
        console.error(e)
        
      }

    }
   
    const gettingUserData = async({dda})=> {

        console.log(dda)

        const allTodos = await API.graphql({query: queries.listEvents, variables: {
            filter: {
                campaignId: {eq: dda}
            }
        }})

        const eventsData = allTodos.data.listEvents.items
        setEvents(eventsData)

    }

    useEffect(() => {
        getUserInfo()

        Linking.getInitialURL().then((ee)=> {
   
            // let bb = Linking.createURL('ticketday://home')
        
        
            console.log(JSON.stringify('SMASTER', ee))
           })




    //   gettingUserData()
    }, [eventInfo, events])
    

  return (
    <View style={{flex: 1, backgroundColor: '#fcfcfc'}}>
      <ImageBackground source={{uri: eventInfo?.image || `https://www.namepros.com/attachments/empty-png.89209/`}} style={{height: height / 3, width: width}} >
        <Animated.View style={{position: 'absolute', left: 0, top: 0}}>
            <TouchableOpacity onPress={()=> navigation.goBack()} style={{height: 60, width: 60, backgroundColor: ourOrange, alignItems: 'center', justifyContent: 'center'}}>
                <Feather name='arrow-left' size={30} color={'#fcfcfc'}  />
            </TouchableOpacity>
        </Animated.View>
        
      </ImageBackground>
      <Animated.View style={{height: 60, width: width, bacc: 'rgba(0,0,0,0.5)', justifyContent: 'center', paddingHorizontal: 15}}>
            <Text style={{fontSize: 18, fontWeight: 'bold'}}>{eventInfo?.name || eventInfo?.title}</Text>
        </Animated.View>
        <View style={{height: 10, width: width, backgroundColor: '#edf1f2'}} />

        <ScrollView showsVerticalScrollIndicator={false}>

        <Animated.View style={{padding: 15}}>
            <Text style={{fontSize: 14}}>Event Details</Text>
            <Text style={{fontSize: 12, marginTop: 20, textAlign: 'justify'}}>{eventInfo?.description}</Text>
        </Animated.View>

        <View style={{height: 10, width: width, backgroundColor: '#edf1f2'}} />

        <Animated.View style={{padding: 15}}>
            <Text style={{fontSize: 14}}>Event Days</Text>
            <Text style={{fontSize: 12, marginTop: 20, textAlign: 'justify'}}></Text>
            {events.map((e, i)=> {
                return(
                    <TouchableOpacity onPress={()=> navigation.navigate('EventTickets', {item: e, imagepass: eventInfo.image})} style={{height: 80, width: width - 30, marginBottom: 10, borderRadius: 12, flexDirection: 'row', borderWidth: 4, borderColor: '#edf1f2', paddingHorizontal: 10, alignItems: 'center'}}>
                        <Image source={{uri: eventInfo.image || imageplace}} style={{height: 60, width: 60, borderRadius: 10}} />
                        <View style={{marginLeft: 25}}>
                            <Text style={{fontSize: 12, fontWeight: 'bold'}}>{e.name}</Text>
                            <View style={{marginTop: 5, flexDirection: 'row'}}>
                                <Feather name='calendar' size={15} color={'gray'} />
                                <Text style={{fontSize: 12, marginLeft: 15}}>{e.eventDate}</Text>
                            </View>
                        </View>

                        <View style={{height: 30, width: 30, borderRadius: 5, alignItems: 'center', justifyContent: 'center', backgroundColor: ourOrange, position: 'absolute', bottom: 15, right: 15}}>
                            <Feather name='arrow-right' size={15} color={'#fcfcfc'} />
                        </View>
                    </TouchableOpacity>
                )
            })}
        </Animated.View>    
        </ScrollView>
    </View>
  )
}

export default EventDetailsLink