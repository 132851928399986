
import { StyleSheet, Text, View, TouchableOpacity, Dimensions, Image, ActivityIndicator, TextInput, Animated } from 'react-native'
import React, {useState, useEffect} from 'react';
import { Auth, API } from 'aws-amplify';
const {width, height} = Dimensions.get('screen');
import { useStateContext } from '../src/contexts/ContextProvider';
import { Feather, FontAwesome, Ionicons, AntDesign, MaterialIcons, Foundation } from '@expo/vector-icons'; 
import * as mutations from '../src/graphql/mutations';


const LoginFlow = () => {

    const {counting, setCounting, authed, setAuthed} = useStateContext();
    const ourOrange = '#FF4F00';

    const [username, setUsername] = useState('')
    const [fName, setFName] = useState('')
    const [otp, setOtp] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [password, setPassword] = useState('')
    const [errorMsg, setErrorMsg] = useState('')
    const [spinning, setSpinning] = useState('')

    const [chooose, setChoose]  = useState('')
    const [showFlags, setShowFlags] = useState(false)
    const [codenflag, setCodenflag] = useState({
      country: 'South Africa',
      flag: 'https://em-content.zobj.net/thumbs/160/whatsapp/116/flag-for-south-africa_1f1ff-1f1e6.png',
      code: '+27'
    })

    const signIn = async()=> {

        setSpinning(true)

        if(username === ''){
            setErrorMsg('Please enter an email address')
            setSpinning(false)
            return
        }

        if(password === ''){
            setErrorMsg('Please enter your password')
            setSpinning(false)
            return
        }

        try {
            await Auth.signIn(username, password).then((e)=> {
                setSpinning(false),
                setAuthed(true)
                
                
            })
        } catch (error) {
            console.error(error),
            setErrorMsg(error.message),
            setSpinning(false)
        }


           
    }

    const signUp = async()=> {
      setSpinning(true)

      // const countryCode = String(codenflag.code)
      // const finalNumber = countryCode.concat('', Number(phoneNumber))  

      try {
        Auth.signUp({
          username: username,
          password: password,
          attributes: {
            email: username
          }
        }).then((ee)=> {

          const inputDataUser = {
            id: ee.userSub,
            firstName: fName,
            email: username,
            // phoneNumber: finalNumber
          }

          try {
            API.graphql({query: mutations.createUserData, variables: {input: inputDataUser}}).then((ed)=> {
               setChoose('OTP'),
               setSpinning(false)
            })
           } catch (error) {
             console.error(error),
             setSpinning(false)
           }



        })
      } catch (error) {
        console.error(error)
      }
    }


    const confirmOTP = async()=> {
      setSpinning(true)


      try {
        await Auth.confirmSignUp(username, otp).then((eff)=> {
          signInB()   
          setSpinning(false) 
        })
      } catch (error) {
        console.error(error),
        setSpinning(false)
      }


    }


    const signInB = async()=> {
      setSpinning(true)
  
      try {
       await Auth.signIn(username, password).then((ee)=> {
        setChoose('')
       })
      } catch (error) {
          console.error(error),
          setSpinning(false)
      }
  
    }


    if(chooose === 'SignUp'){
      return(
        <View style={{flex: 1, backgroundColor: '#fcfcfc', padding: 20}}>

        {showFlags && 
          <View style={{width: width, height: height, backgroundColor: 'rgba(0,0,0,0.8)', position: 'absolute', zIndex: 15000, alignItems: 'center', justifyContent: 'center'}}>
              <View style={{height: height / 3, width: width - 90, backgroundColor: '#fcfcfc', borderRadius: 10, padding: 20}}>
                <Text style={{fontWeight: 'bold'}}>Pick your country code</Text>
              
                <View style={{marginTop: 40}}>

                  <TouchableOpacity style={{flexDirection: 'row'}}
                    onPress={()=> {
                      setCodenflag({
                        country: 'South Africa',
                        flag: 'https://em-content.zobj.net/thumbs/160/whatsapp/116/flag-for-south-africa_1f1ff-1f1e6.png',
                        code: +27
                      }), setShowFlags(!showFlags)}
                    }
                     
                  >
                    <View style={{alignItems: 'center', justifyContent: 'center'}}>
                      <Image style={{height: 30, width: 30}}
                        source={{uri: 'https://em-content.zobj.net/thumbs/160/whatsapp/116/flag-for-south-africa_1f1ff-1f1e6.png'}}
                      />
                    </View>

                    <Text style={{marginLeft: 10, fontWeight: 'bold', color: 'gray', opacity: 0.6}}>(+27)</Text>

                    <Text style={{marginLeft: 15, fontWeight: 'bold', color: 'gray', opacity: 0.6}}>South Africa</Text>
                  </TouchableOpacity>

                  <TouchableOpacity style={{flexDirection: 'row', marginTop: 25}}
                    onPress={()=> {
                      setCodenflag({
                        country: 'Eswatini',
                        flag: 'https://em-content.zobj.net/thumbs/120/apple/354/flag-eswatini_1f1f8-1f1ff.png',
                        code: +268
                      }),
                      setShowFlags(!showFlags)}
                    }
                     
                  >
                    <View style={{alignItems: 'center', justifyContent: 'center'}}>
                      <Image style={{height: 30, width: 30}}
                        source={{uri: 'https://em-content.zobj.net/thumbs/120/apple/354/flag-eswatini_1f1f8-1f1ff.png'}}
                      />
                    </View>

                    <Text style={{marginLeft: 10, fontWeight: 'bold', color: 'gray', opacity: 0.6}}>(+268)</Text>

                    <Text style={{marginLeft: 15, fontWeight: 'bold', color: 'gray', opacity: 0.6}}>Eswatini</Text>
                  </TouchableOpacity>

                </View>
              
              
              </View>



          </View>
        }





    <Text style={{marginTop: 15, fontSize: 16, fontWeight: 'bold' }}>Create an account</Text>
    <Text style={{fontSize: 12, color: 'gray'}}>Enter your details below</Text>

    {errorMsg && <Text style={{marginBottom: 5, fontSize: 12, color: 'red'}}>{errorMsg}</Text>}


    <View style={{flexDirection: 'row', marginTop: 30}}>
      <TextInput placeholder='Your Name' value={fName} onChangeText={setFName} style={{borderWidth: 0.5, width: width - 20, height: 45, paddingLeft: 20, borderRadius: 5}} />
    </View>

    {/* <View style={{flexDirection: 'row', marginTop: 30}}>
      <TextInput placeholder='Surname' value={sName} onChangeText={setSName} style={{borderWidth: 0.5, width: width - 20, height: 45, paddingLeft: 20, borderRadius: 5}} />
    </View> */}

    <View style={{ marginTop: 30}}>
      <TextInput placeholder='E-Mail Address' value={username} onChangeText={setUsername} style={{borderWidth: 0.5, width: width - 40, height: 45, paddingLeft: 20, borderRadius: 5}} />
      <Text style={{fontSize: 10, color: 'gray', marginTop: 5}}>Please enter your email address in full</Text>
    </View>

    <View style={{ marginTop: 30}}>
      <TextInput placeholder='Create Password' secureTextEntry value={password} onChangeText={setPassword} style={{borderWidth: 0.5, width: width - 40, height: 45, paddingLeft: 20, borderRadius: 5}} />    
   
      <View>
      <Text style={{fontSize: 10, color: 'gray', marginTop: 5}}>Password must be 8 characters or more</Text>
      </View>
   
    </View>

    <Animated.View>
      <TouchableOpacity onPress={()=> signUp()} style={{height: 45, width: width - 40, marginTop: 30,  backgroundColor: ourOrange, alignItems: 'center', justifyContent: 'center', borderRadius: 5}}>
        
       {spinning &&  <View style={{position: 'absolute', left: 10}}>
                <ActivityIndicator size={'small'} color={'#fcfcfc'} />
            </View> }
        <Text style={{fontWeight: 'bold', color: '#fcfcfc'}}>Create an account</Text>
      </TouchableOpacity>

      <View>
        <View style={{flexDirection: 'row', alignItems: 'center', marginTop: 10}}>
          <Text style={{fontSize: 12}}>Already have an account ? </Text>
          <TouchableOpacity style={{marginLeft: 5}}
            onPress={()=> setChoose('')}
          >
            
            <Text style={{fontSize: 12, fontWeight: 'bold', color: ourOrange}}>Log in</Text>
          </TouchableOpacity>
        </View>
      </View>
    </Animated.View>


  </View>
      )
    }

    if(chooose === 'OTP'){
      return(
        <View style={{flex: 1, backgroundColor: '#fcfcfc', padding: 20}}>

        {showFlags && 
          <View style={{width: width, height: height, backgroundColor: 'rgba(0,0,0,0.8)', position: 'absolute', zIndex: 15000, alignItems: 'center', justifyContent: 'center'}}>
              <View style={{height: height / 3, width: width - 90, backgroundColor: '#fcfcfc', borderRadius: 10, padding: 20}}>
                <Text style={{fontWeight: 'bold'}}>Pick your country code</Text>
              
                <View style={{marginTop: 40}}>

                  <TouchableOpacity style={{flexDirection: 'row'}}
                    onPress={()=> {
                      setCodenflag({
                        country: 'South Africa',
                        flag: 'https://em-content.zobj.net/thumbs/160/whatsapp/116/flag-for-south-africa_1f1ff-1f1e6.png',
                        code: +27
                      }), setShowFlags(!showFlags)}
                    }
                     
                  >
                    <View style={{alignItems: 'center', justifyContent: 'center'}}>
                      <Image style={{height: 30, width: 30}}
                        source={{uri: 'https://em-content.zobj.net/thumbs/160/whatsapp/116/flag-for-south-africa_1f1ff-1f1e6.png'}}
                      />
                    </View>

                    <Text style={{marginLeft: 10, fontWeight: 'bold', color: 'gray', opacity: 0.6}}>(+27)</Text>

                    <Text style={{marginLeft: 15, fontWeight: 'bold', color: 'gray', opacity: 0.6}}>South Africa</Text>
                  </TouchableOpacity>

                  <TouchableOpacity style={{flexDirection: 'row'}}
                    onPress={()=> {
                      setCodenflag({
                        country: 'Eswatini',
                        flag: 'https://em-content.zobj.net/thumbs/160/whatsapp/116/flag-for-south-africa_1f1ff-1f1e6.png',
                        code: +268
                      }),
                      setShowFlags(!showFlags)}
                    }
                     
                  >
                    <View style={{alignItems: 'center', justifyContent: 'center'}}>
                      <Image style={{height: 30, width: 30}}
                        source={{uri: 'https://em-content.zobj.net/thumbs/160/whatsapp/116/flag-for-south-africa_1f1ff-1f1e6.png'}}
                      />
                    </View>

                    <Text style={{marginLeft: 10, fontWeight: 'bold', color: 'gray', opacity: 0.6}}>(+268)</Text>

                    <Text style={{marginLeft: 15, fontWeight: 'bold', color: 'gray', opacity: 0.6}}>Eswatini</Text>
                  </TouchableOpacity>

                </View>
              
              
              </View>



          </View>
        }





    <Text style={{marginTop: 15, fontSize: 16, fontWeight: 'bold' }}>Create an account</Text>
    <Text style={{fontSize: 12, color: 'gray'}}>Enter your details below</Text>

    {errorMsg && <Text style={{marginBottom: 5, fontSize: 12, color: 'red'}}>{errorMsg}</Text>}


    

    <View style={{flexDirection: 'row', marginTop: 30}}>
      <TextInput editable={false} placeholder='E-Mail Address' value={username} style={{borderWidth: 0.5, width: width - 20, height: 45, paddingLeft: 20, borderRadius: 5}} />
    </View>

    <View style={{flexDirection: 'row', marginTop: 30}}>
      <TextInput placeholder='One-Time-Pin' value={otp} onChangeText={setOtp} style={{borderWidth: 0.5, width: width - 20, height: 45, paddingLeft: 20, borderRadius: 5}} />
    </View>

    <Animated.View>
      <TouchableOpacity onPress={()=> confirmOTP()} style={{height: 45, width: width - 40, marginTop: 30,  backgroundColor: ourOrange, alignItems: 'center', justifyContent: 'center', borderRadius: 5}}>
        
       {spinning &&  <View style={{position: 'absolute', left: 10}}>
                <ActivityIndicator size={'small'} color={'#fcfcfc'} />
            </View> }
        <Text style={{fontWeight: 'bold', color: '#fcfcfc'}}>Confirm OTP</Text>
      </TouchableOpacity>

      <View>
        <View style={{flexDirection: 'row', alignItems: 'center', marginTop: 10}}>
          <Text style={{fontSize: 12}}>Didn't get an otp ? </Text>
          <TouchableOpacity style={{marginLeft: 5}}
            onPress={()=> setChoose('')}
          >
            
            <Text style={{fontSize: 12, fontWeight: 'bold', color: ourOrange}}>Resend</Text>
          </TouchableOpacity>
        </View>
      </View>
    </Animated.View>


  </View>
      )
    }


  return (
    <View style={{flex: 1, backgroundColor: '#fcfcfc', padding: 20}}>
    <Text style={{marginTop: 15, fontSize: 16, fontWeight: 'bold' }}>Log in</Text>
    <Text style={{fontSize: 12, color: 'gray'}}>Looks like you are not signed in</Text>

    {errorMsg && <Text style={{marginBottom: 5, fontSize: 12, color: 'red'}}>{errorMsg}</Text>}
   
    
    <View style={{flexDirection: 'row', marginTop: 30}}>
      <TextInput placeholder='E-Mail Address' value={username} onChangeText={setUsername} style={{borderWidth: 0.5, width: width - 20, height: 45, paddingLeft: 20, borderRadius: 5}} />
    </View>

    <View style={{ marginTop: 30}}>
      <TextInput placeholder='Password' secureTextEntry value={password} onChangeText={setPassword} style={{borderWidth: 0.5, width: width - 40, height: 45, paddingLeft: 20, borderRadius: 5}} />
      
      <TouchableOpacity style={{marginTop: 5}}>
        <Text style={{fontSize: 12}}>Forgot Password ? </Text>
      </TouchableOpacity>
      
    
    </View>

    <Animated.View>
      <TouchableOpacity onPress={()=> signIn()} style={{height: 45, width: width - 40, marginTop: 30,  backgroundColor: ourOrange, alignItems: 'center', justifyContent: 'center', borderRadius: 5}}>
        
       {spinning &&  <View style={{position: 'absolute', left: 10}}>
                <ActivityIndicator size={'small'} color={'#fcfcfc'} />
            </View> }
        <Text style={{fontWeight: 'bold', color: '#fcfcfc'}}>Sign In</Text>
      </TouchableOpacity>

      <View>
        <View style={{flexDirection: 'row', alignItems: 'center', marginTop: 10}}>
          <Text style={{fontSize: 12}}>Don't have an account ? </Text>
          <TouchableOpacity style={{marginLeft: 5}}
            onPress={()=> setChoose('SignUp')}
          >
            
            <Text style={{fontSize: 12, fontWeight: 'bold', color: ourOrange}}>Create An Account</Text>
          </TouchableOpacity>
        </View>
      </View>
    </Animated.View>


  </View>
  )
}

export default LoginFlow

const styles = StyleSheet.create({})